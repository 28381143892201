<template>
	<div class="invoices">
		<FilterDrawer />
		<div v-if="instanceId && !loading" class="py-2" style="font-size: 18px">
			<a-icon type="arrow-left" class="mr-3" @click="$router.back()" />
			<strong>{{ selectedInstanceName }}</strong>
		</div>
		<a-card class="mt-3" :title="null">
			<div class="dF aC jSB">
				<div class="dF aC jSB">
					<div class="text-md">Summary</div>
					<div class="ml-5" style="color: #9ea0a5"> {{ `${totalInvoices} total` }} </div>
				</div>
				<div class="dF aC" style="gap: 30px">
					<div>
						<a-input placeholder="Search your projects..." v-model="search" size="large" allowClear>
							<a-tooltip slot="prefix" title="Search your projects"
								overlayClassName="change-tooltip-color">
								<a-icon type="search" style="color: black" />
							</a-tooltip>
						</a-input>
					</div>
					<div @click="$store.commit('OPEN_FILTER')" class="dF aC px-3 py-2 ml-3"
						style="background-color:white; border-radius:4px; border:1px solid #ECEBED; cursor:pointer">
						<div class="mr-2">Filters</div>
						<i class="fe fe-filter" />
					</div>
				</div>
			</div>
			<hr style="margin-left: -24px; margin-right: -24px" />
			<a-table :rowKey="(e, i) => i" :columns="columns" :dataSource="invoices"
				:loading="loading"
				:pagination="{
                    current: currentPage,
                    total: totalInvoices,
                    pageSize: pageSize,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30', '40', '50', '75', '100']
                }"
				@change="handleChange"
				:scroll="{ x: 1200 }" class="white-table">
				<div slot="readableId" slot-scope="invoice" class="text-dark" style="font-size: 15px;">
					{{ invoice.instance?.readableId || '' }}
				</div>
				<div slot="instance" slot-scope="invoice" class="text-dark" style="font-size: 15px; min-width: 100px">
					{{ invoice.instance?.name }}
				</div>
				<div slot="dueDate" slot-scope="invoice" style="color: #9ea0a5">
					{{ invoice.validUntil ? $formatDate(+invoice.validUntil) : "N/A"}}
				</div>
				<div slot="invoiceDate" slot-scope="invoice" style="color: #9ea0a5; min-width: 150px">
					{{ invoice.createdAt ? $formatDate(invoice.createdAt) : "N/A" }}
				</div>
				<div slot="invoiceNumber" slot-scope="invoice">
					{{ invoice.invoiceNumber || "N/A" }}
				</div>
				<div slot="projectStatus" slot-scope="invoice" class="text-center">
					<div v-if="invoice.instance">
						<span v-if="invoice.instance.deleted">Deleted</span>
						<span v-else-if="invoice.instance.susspended"> Locked </span>
						<span v-else> Active</span>
					</div>
					<div v-else>
						Active
					</div>
				</div>
				<div slot="total" slot-scope="invoice" class="text-dark" style="font-size: 15px">
					{{ !invoice ? "$0" : `$${invoice.total}` }} ({{ invoice.currency || 'USD' }})
				</div>
				<div slot="transaction" slot-scope="invoice">
					<div class="dF aC" style="gap: 10px" v-if="invoice.paymentMode !== 'offline'">
						<img alt="no-card" :src="getTransactionDetails(invoice).imageUrl" width="40%" height="40%" />
						{{ getTransactionDetails(invoice).number }}
					</div>
				</div>
				<div slot="status" slot-scope="invoice">
					<span v-if="invoice" class="py-1 px-2 paid-button" :style="invoice.paid
						? 'background-color:#28C791'
						: 'background-color:#FD647C'
						">
						{{ invoice.paid ? "PAID" : "UNPAID" }}
					</span>
					<span v-else>N/A</span>
				</div>
				<div slot="refundTotal" slot-scope="invoice">
					<span v-if="invoice.refunds">
						{{ calculateTotalRefund(invoice.refunds) }} ({{ invoice.currency || "USD" }})
					</span>
				</div>
				<div slot="refundStatus" slot-scope="invoice">
					<span class="paid-button py-1 px-2" :style="invoice.refundStatus
						? invoice.refundStatus === 'partial'
							? 'background-color:#ffc107'
							: 'background-color: var(--primary)'
						: ''
						">
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title" v-if="invoice.refunds?.length">
								<div v-for="r in invoice.refunds" :key="r.id">
									<p> {{ r.reason || 'N/A' }}: ${{ r.amount }}({{ invoice.currency || "USD" }}) </p>
								</div>
							</template>
							{{ invoice.refundStatus ? invoice.refundStatus === "partial" ? "PARTIAL" : "FULL" : "" }}
						</a-tooltip>
					</span>
				</div>
				<div slot="operation" slot-scope="invoice">
					<div v-if="invoice" class="dF aC jSB" style="gap: 5px;">
						<a-button @click="$emit('viewInvoice', invoice)" type="primary" ghost>
							VIEW INVOICE
						</a-button>
						<a-button @click="payAgain(invoice)"
							:disabled="!(!invoice.paid && invoice.paymentMode !== 'offline' && user.id === invoice.instance.billingOwner.id)">
							PAY
						</a-button>
						<a-button @click="printInvoice(invoice, false);" type="link" style="padding: 0px"
							icon="download" size="small">
							PDF
						</a-button>
						<a-button @click="printInvoice(invoice, true);" type="link" style="padding: 0px" icon="eye"
							size="small">
							PDF
						</a-button>
					</div>
				</div>
			</a-table>
		</a-card>

		<div>
			<InvoicePDF :invoice="invoiceToDownload" :userPaymentMethods="userPaymentMethods"
				@close="invoiceToDownload = null; previewModal = false;" :previewModal="previewModal" />
		</div>
	</div>
</template>

<script>
import InvoicePDF from "@/components/common/InvoicePDF";
import FilterDrawer from '@/components/common/FilterDrawer'
import moment from "moment";
import _ from "lodash";

export default {
	components: {
		InvoicePDF,
		FilterDrawer
	},
	props: {
		userPaymentMethods: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			loading: false,
			invoiceToDownload: null,
			previewModal: false,
			search: '',
			currentPage: 1,
            pageSize: 10,
            totalInvoices: 0,
			invoices: [],
			sortedInfo: {
				key: "updatedAt",
				order: "DESC",
			},
			selectedInstanceName: '',
			columns: [
				{
					title: "Project Id",
					key: "instance.readableId",
					scopedSlots: { customRender: "readableId" },
                    sorter: true,
					width: "7%"
				},
				{
					title: "Project Name",
					key: "instance.name",
                    sorter: true,
					scopedSlots: { customRender: "instance" },
				},
				{
					title: "Invoice #",
					key: "invoiceNumber",
					scopedSlots: { customRender: "invoiceNumber" },
                    sorter: true,
					width: "7%"
				},
				{
					title: "Invoice Date",
					key: "createdAt",
					scopedSlots: { customRender: "invoiceDate" },
                    sorter: true,
					width: "7%"
				},
				{
					title: "Next Invoice Date",
					key: "validUntil",
					scopedSlots: { customRender: "dueDate" },
                    sorter: true,
					width: "7%"
				},
				{
					title: "Amount",
					key: "total",
					scopedSlots: { customRender: "total" },
                    sorter: true,
					width: "10%"
				},
				{
					title: "Transaction",
					key: "transaction",
					scopedSlots: { customRender: "transaction" },
					width: "10%"
				},
				{
					title: "Status",
					key: "paid",
					scopedSlots: { customRender: "status" },
                    sorter: true,
					width: "7%"
				},
				{
					title: "Refund Status",
					key: "refundStatus",
					scopedSlots: { customRender: "refundStatus" },
                    sorter: true,
					width: "7%"
				},
				{
					title: "Project Status",
					key: "instance.susspended",
					scopedSlots: { customRender: "projectStatus" },
                    sorter: true,
					width: "7%"
				},
				{
					title: "",
					key: "operation",
					scopedSlots: { customRender: "operation" },
					width: "22%"
				},
			]
		};
	},

	computed: {
		user() {
			return this.$store.state.user.user || {}
		},

		instanceId() {
			return this.$route.query.instance;
		},

		invoiceId() {
			return this.$route.query.invoice || this.$route.query.invoiceId;
		},

		filter() {
			return JSON.parse(JSON.stringify(this.$store.state.filterDrawer.filter));
		},

		filterQuery() {
			let filterQuery = ''
			const filter = this.filter;

			if (!filter.showDelete) {
				filterQuery += `&instance.deleted=false`
			}

			if (filter.showLatestOnly) {
				filterQuery += `&showLatestOnly=true`
			}

			if (filter.status[0] !== "any") {
				filter.status.forEach(status => {
					filterQuery += `&paid_in[]=${status === "paid" ? true : false}`
				})
			}
			if (filter.productType[0] !== "any") {
				filter.productType.forEach(productType => {
					filterQuery += `&instance.productType_in[]=${productType}`
				})
			}
			if(filter.price[0] !== 0) {
				filterQuery += `&total_gte=${filter.price[0]}`
			}
			if(filter.price[1] !== 0) {
				filterQuery += `&total_lte=${filter.price[1]}`
			}

			if (filter.timeFrame[0] !== null) {
				const startDate = +moment(filter.timeFrame[0]).startOf("day").format("x")
				filterQuery += `&createdAt_gte=${new Date(startDate).toISOString()}`
			}

			if (filter.timeFrame[1] !== null) {
				const endDate = +moment(filter.timeFrame[1]).startOf("day").format("x")
				filterQuery += `&validUntil_lte=${endDate}`
			}

			return filterQuery
		},

		apiQuery() {
			let query = this.search ? `?_q=${this.search}` : '';
			let filterQuery = this.filterQuery;
			if(query && filterQuery) {
				query += filterQuery
			} else if(filterQuery) {
				filterQuery = filterQuery.startsWith('&') ? filterQuery.slice(1) : filterQuery
				query = `?${filterQuery}`
			}

			if(this.instanceId) {
				query += `${query ? '&' : ''}instance._id=${this.instanceId}`
			}
			if(this.invoiceId) {
				query += `${query ? '&' : ''}_id=${this.invoiceId}`
			}

			return query;
		}
	},

	watch: {
        search() {
            this.searchDebounce();
        },

		filter: {
			deep: true,
			handler() {
				this.currentPage = 1;
				this.getInvoiceData()
			}
		},
    },

	created() {
        this.getInvoiceData();
    },

	methods: {
		getInvoiceData() {
            this.getTotalInvoiceCount();
            this.getInvoices();
		},

		searchDebounce: _.debounce(function () {
			this.currentPage = 1;
			this.getInvoiceData();
        }, 1000),

		async getTotalInvoiceCount() {
			try {
				this.totalInvoices = 0;
                const { data } = await this.$api.get(`invoices/count${this.apiQuery}`);
				this.totalInvoices = data;
			} catch (error) {
				this.$toastError(error, 'Error while fetching total invoice count. Please try again')
			}
		},

		async getInvoices() {
			this.loading = true;
			try {
				this.invoices = [];
				let pagination = `&_start=${(this.currentPage - 1) * this.pageSize}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}`;
				if(!this.apiQuery) {
					pagination = pagination.startsWith('&') ? pagination.replace('&', '?') : pagination
				}
				const { data } = await this.$api.get(`invoices${this.apiQuery}${pagination}`);
				this.invoices = data.map(invoice => {
					return {
						...invoice,
						id: invoice._id
					}
				});

				if(this.instanceId) {
					this.selectedInstanceName = this.invoices[0]?.instance?.name || ''
				}

				if (this.invoiceId) {
					const found = this.invoices.find(c => c.id === this.invoiceId);
					if (found) {
						this.$emit('viewInvoice', found);
					}
				}
			} catch (error) {
				this.$toastError(error, 'Error while fetching invoices. Please try again')
			} finally {
				this.loading = false;
			}
		},

		handleChange(pagination, filter, sorter) {
			if (pagination) {
				this.currentPage = pagination.current;
				this.pageSize = pagination.pageSize;
			}
            if (sorter?.column) {
                this.sortedInfo = {
                    key: sorter.columnKey,
                    order: sorter.order === "descend" ? "DESC" : "ASC",
                };
            }
            this.getInvoices();
        },

		payAgain(invoice) {
			if (!(!invoice.paid && invoice.paymentMode !== 'offline' && this.user.id === invoice.instance.billingOwner?.id)) return;
			this.$message.info("Payment initiated.");
			this.$api
				.get(`/invoices/tryAgain/${invoice.id}`)
				.then(({ data }) => {
					if (data?.success) {
						this.$message.success("Payment done successfully.");
						let index = this.invoices.findIndex(
							(x) => x.invoice.id == data.id
						);
						if (index != -1) {
							this.invoices[index].invoice.paid = data.paid;
						}
					} else {
						this.$message.error(`Payment failed. ${data && data.message && data.message.message || ''}`);
					}

				})
				.catch((err) => {
					console.error(
						"Error while making payment for the invoice",
						err
					);
				});
		},

		calculateTotalRefund(refunds) {
			const total = refunds.reduce((a, b) => { return a + b.amount;}, 0).toFixed(2);
			return total ? "$" + total : "";
		},

		getTransactionDetails(invoice) {
			if (invoice?.instance?.paymentMethod) {
				const payment = invoice.instance.paymentMethod;
				return {
					imageUrl: payment.details.ccBrand
						? require(`@/assets/${payment.details.ccBrand}.svg`)
						: "N/A",
					number: payment.details.cardNo
						? "ending " + payment.details.cardNo.slice(-4)
						: "N/A",
				};
			} else {
				return {
					imageUrl: "N/A",
					number: "N/A",
				};
			}
		},

		printInvoice(invoice, previewModal = false) {
			let selectedInvoice = JSON.parse(JSON.stringify(invoice));
			if (selectedInvoice.details?.featureBlocks?.length) {
				if (!selectedInvoice.details.selectedPackageExtras) {
					selectedInvoice.details.selectedPackageExtras = []
				}
				selectedInvoice.details.featureBlocks.forEach(
					(x) => {
						if (x.name === "Training hours") {
							const index = selectedInvoice.details.selectedPackageExtras.findIndex(p => p.name === "Training Hours");
							if (index >= 0) {
								selectedInvoice.details.selectedPackageExtras[index] = {
									...x,
									name: "Training Hours",
									quantity: x.quantity,
								}
							} else {
								selectedInvoice.details.selectedPackageExtras.push(
									{
										...x,
										name: "Training Hours",
										quantity: x.quantity,
									}
								);
							}
						} else {
							selectedInvoice.details.selectedPackageExtras.push({ ...x, quantity: x.quantity ? x.quantity : (x.value && Number(x.value)) });
						}
					}
				);
			}

			this.previewModal = previewModal
			this.invoiceToDownload = selectedInvoice
		},
	},
};
</script>

<style lang="scss" scoped>
.paid-button {
	color: #fff;
	border-radius: 4px;
	font-size: 14px;
	text-align: center;
	padding: 2px 8px;
}
</style>

<style lang="scss">
.invoices .ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
	padding: 10px
}

.invoices .ant-card-body {
	padding: 12px
}
</style>
