<template>
    <div class="mb-10">
        <a-modal
            v-model="colourModal.visible"
            title="Colors"
            @ok="handleColorChange"
        >
            <div class="dF aC" style="flex-wrap: wrap">
                <div
                    class="px-1 py-1"
                    v-for="(color, colorI) in colourList"
                    :key="color + colorI"
                    :value="color"
                >
                    <div
                        class="relative"
                        @click="selectedColor = color"
                        :style="{ 'background-color': color }"
                        style="
                            border-radius: 50%;
                            height: 20px;
                            width: 20px;
                            cursor: pointer;
                        "
                    >
                        <div
                            v-if="selectedColor === color"
                            class="absolute"
                            style="
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            <i class="fe fe-check" />
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
        <div v-if="!instances.length && !loading" class="align-center mt-5">
            <img
                style="width: 100%; max-width: 500px"
                src="@/assets/noinstance_found.svg"
                alt="No instances found"
            />
            <h3 class="mt-5">No Project Found</h3>
        </div>
        <div v-if="(instances.length || loading) && view === 'list'">
            <a-table
                :columns="columns"
                :data-source="instances"
                :rowKey="(record) => record.key"
                :pagination="false"
                :expandedRowKeys="currentExpandedRowKeys"
                :expandIconAsCell="false"
                :expandIconColumnIndex="-1"
                class="list-view"
				:scroll="{x: 1100}"
            >
				<div slot="action" slot-scope="instance">
					<div class="instance-row">
                        <div
                            class="icon-option"
                        >
                            <div class="hide-hexagon hexagon hexagon2">
                                <div class="hexagon-in1">
                                    <div
                                        class="hexagon-in2"
                                        :style="{
                                            'background-color':
                                                getBackground(instance),
                                        }"
                                    ></div>
                                </div>
                            </div>
                            <a-popover
                                trigger="click"
                                placement="bottomLeft"
                                overlayClassName="remove-padding"
                            >
                                <div slot="content">
                                    <div
                                        @click="colourInstance(instance)"
                                        class="popover-option"
                                    >
                                        Color
                                    </div>
                                    <div
                                        @click="settingInstance(instance.id)"
                                        class="popover-option"
                                    >
                                        Settings
                                    </div>
                                    <div
                                        v-if="instance.own || instance.role?.permissions?.billing >= 10"
                                        @click="billingInstance(instance.id)"
                                        class="popover-option"
                                    >
                                        Billing
                                    </div>
                                    <div
                                        v-if="instance.own"
                                        class="popover-option"
                                        @click="
                                            selectedInstance = instance;
											deleteAccepted = false;
											downloadData = false;
                                            deleteModal = true;
                                        "
                                    >
                                        Delete
                                    </div>
                                </div>
                                <div class="option-button" tabindex="0">
                                    <em
                                        style="font-size: 25px; cursor: pointer"
                                        :style="{
                                            color: getBackground(instance),
                                        }"
                                        class="fe fe-more-vertical"
                                    />
                                </div>
                            </a-popover>
                        </div>
                    </div>
				</div>
                <div slot="name" slot-scope="instance" class="dF">
                    <div>
                        <span>{{ instance.name }}</span>
                        <br />
                        <span
                            v-if="instance.allChild && instance.allChild.length"
                            style="color: var(--med-gray)"
                        >
                            {{ instance.allChild.length }} projects linked
                            <span @click="handleRowExpand(instance.key)">
                                <a-icon
                                    type="down"
                                    v-if="
                                        currentExpandedRowKeys.indexOf(
                                            instance.key
                                        )
                                    "
                                />
                                <a-icon
                                    type="up"
                                    v-if="
                                        !currentExpandedRowKeys.indexOf(
                                            instance.key
                                        )
                                    "
                                />
                            </span>
                        </span>
                    </div>
                </div>
                <div
                    slot="expandedRowRender"
                    slot-scope="instance"
                    class="dF aC"
                    style="margin: 0; gap: 10px"
                >
                    <span> Linked Projects: </span>
                    <span
                        v-for="child in instance.allChild"
                        :key="child.id"
                        class="child"
                    >
                        {{ child.name }}
                    </span>
                </div>
				<div slot="readableId" slot-scope="instance">
					{{
						instance.readableId || ''
					}}
                </div>
                <div slot="productType" slot-scope="instance">
                    {{
                        instance.productType === "lowrise"
                            ? "Low Rise"
                            : "High Rise"
                    }}
                </div>
                <div
                    slot="createdAt"
                    slot-scope="instance"
                    style="color: var(--med-gray)"
                >
                    {{ convertDate(instance.createdAt) }}
                </div>
                <div
                    slot="lastVisitedAt"
                    slot-scope="instance"
                    style="color: var(--med-gray)"
                >
                    {{
                        lastVisited[instance.id]
                            ? convertDate(lastVisited[instance.id])
                            : ""
                    }}
                </div>
                <div slot="owned/shared" slot-scope="instance">
                    <a-row class="dF aC" :gutter="25">
                        <a-col :span="3" />
                        <a-col :span="6">
                            <div style="font-size: 16px">
                                <a-tooltip
                                    slot="prefix"
                                    :title="
                                        instance.own
                                            ? 'Owned'
                                            : 'Shared with you'
                                    "
                                    overlayClassName="change-tooltip-color"
                                >
                                    <em
                                        style="color: #d0c9d6"
                                        v-if="instance.own"
                                        class="fe fe-user"
                                    />
                                    <em
                                        style="color: #d0c9d6"
                                        v-else
                                        class="fe fe-users"
                                    />
                                </a-tooltip>
                            </div>
                        </a-col>
                        <a-col :span="6">
                            <div style="font-size: 16px">
                                <a-tooltip
                                    v-if="linkedInstances.includes(instance.id)"
                                    slot="prefix"
                                    title="Linked"
                                    overlayClassName="change-tooltip-color"
                                >
                                    <em
                                        style="color: #d0c9d6"
                                        class="fe fe-link"
                                    />
                                </a-tooltip>
                            </div>
                        </a-col>
                    </a-row>
                </div>
                <div slot="parent" slot-scope="instance">
                    <div>
                        <a-tooltip
                            v-if="
                                instance.allChild &&
                                instance.allChild.length > 0
                            "
                            slot="prefix"
                            title="Parent Project"
                            overlayClassName="change-tooltip-color"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20.4 16.32"
                                fill="#FF9027"
                            >
                                <path
                                    id="Icon_awesome-crown"
                                    data-name="Icon awesome-crown"
                                    d="M16.83,14.28H3.57a.511.511,0,0,0-.51.51v1.02a.511.511,0,0,0,.51.51H16.83a.511.511,0,0,0,.51-.51V14.79A.511.511,0,0,0,16.83,14.28Zm2.04-10.2a1.53,1.53,0,0,0-1.53,1.53,1.5,1.5,0,0,0,.14.631L15.172,7.624a1.019,1.019,0,0,1-1.409-.37l-2.6-4.545a1.53,1.53,0,1,0-1.932,0l-2.6,4.545a1.019,1.019,0,0,1-1.409.37l-2.3-1.383a1.529,1.529,0,1,0-1.393.9,1.562,1.562,0,0,0,.245-.025l2.3,6.145H16.32l2.3-6.145a1.562,1.562,0,0,0,.245.025,1.53,1.53,0,1,0,0-3.06Z"
                                    class="cls-1"
                                ></path>
                            </svg>
                        </a-tooltip>
                    </div>
                </div>
            </a-table>
        </div>
        <div v-if="(instances.length || loading) && view === 'grid'">
            <div class="instance-grid dF fC aC mt-2" style="text-align: center">
                <a-card v-for="(instance, index) in instances" :key="index">
                    <div slot="cover" class="instance-row dF fC aC mt-4">
                        <div
                            class="icon-option"
                            :class="$mq != 'sm' ? 'mx-4' : 'mr-2'"
                        >
                            <div
                                class="hexagon hexagon2"
                                style="cursor: default"
                            >
                                <div class="hexagon-in1">
                                    <div
                                        class="hexagon-in2"
                                        :style="{
                                            'background-color':
                                                getBackground(instance),
                                        }"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template slot="actions" class="ant-card-actions">
                        <a-row class="dF aC ml-2">
                            <a-col :span="2">
                                <a-icon
                                    type="clock-circle"
                                    style="color: #d0c9d6"
                            /></a-col>
                            <a-col :span="11">
                                <span class="text-black">{{
                                    convertDate(instance.createdAt)
                                }}</span>
                            </a-col>
                            <a-col :span="2">
                                <div style="font-size: 16px">
                                    <a-tooltip
                                        slot="prefix"
                                        :title="
                                            instance.own
                                                ? 'Owned'
                                                : 'Shared with you'
                                        "
                                        overlayClassName="change-tooltip-color"
                                    >
                                        <em
                                            style="color: #d0c9d6"
                                            v-if="instance.own"
                                            class="fe fe-user"
                                        />
                                        <em
                                            style="color: #d0c9d6"
                                            v-else
                                            class="fe fe-users"
                                        />
                                    </a-tooltip>
                                </div>
                            </a-col>
                            <a-col :span="3">
                                <div style="font-size: 16px">
                                    <a-tooltip
                                        v-if="
                                            linkedInstances.includes(
                                                instance.id
                                            )
                                        "
                                        slot="prefix"
                                        title="Linked"
                                        overlayClassName="change-tooltip-color"
                                    >
                                        <em
                                            style="color: #d0c9d6"
                                            class="fe fe-link"
                                        />
                                    </a-tooltip>
                                </div>
                            </a-col>
                            <a-col :span="3">
                                <div>
                                    <a-tooltip
                                        v-if="
                                            instance.allChild &&
                                            instance.allChild.length > 0
                                        "
                                        slot="prefix"
                                        title="Parent Project"
                                        overlayClassName="change-tooltip-color"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20.4 16.32"
                                            fill="#FF9027"
                                        >
                                            <path
                                                id="Icon_awesome-crown"
                                                data-name="Icon awesome-crown"
                                                d="M16.83,14.28H3.57a.511.511,0,0,0-.51.51v1.02a.511.511,0,0,0,.51.51H16.83a.511.511,0,0,0,.51-.51V14.79A.511.511,0,0,0,16.83,14.28Zm2.04-10.2a1.53,1.53,0,0,0-1.53,1.53,1.5,1.5,0,0,0,.14.631L15.172,7.624a1.019,1.019,0,0,1-1.409-.37l-2.6-4.545a1.53,1.53,0,1,0-1.932,0l-2.6,4.545a1.019,1.019,0,0,1-1.409.37l-2.3-1.383a1.529,1.529,0,1,0-1.393.9,1.562,1.562,0,0,0,.245-.025l2.3,6.145H16.32l2.3-6.145a1.562,1.562,0,0,0,.245.025,1.53,1.53,0,1,0,0-3.06Z"
                                                class="cls-1"
                                            ></path>
                                        </svg>
                                    </a-tooltip>
                                </div>
                            </a-col>
                            <a-col :span="2">
                                <a-popover
                                    trigger="click"
                                    placement="bottomLeft"
                                    overlayClassName="remove-padding"
                                >
                                    <div slot="content">
                                        <div
                                            @click="colourInstance(instance)"
                                            class="popover-option"
                                        >
										Color
                                        </div>
                                        <div
                                            @click="
                                                settingInstance(instance.id)
                                            "
                                            class="popover-option"
                                        >
                                            Settings
                                        </div>
                                        <div
                                            v-if="instance.own || instance.role?.permissions?.billing >= 10"
                                            @click="
                                                billingInstance(instance.id)
                                            "
                                            class="popover-option"
                                        >
                                            Billing
                                        </div>
                                        <div
                                            v-if="instance.own"
                                            class="popover-option"
                                            @click="
                                                selectedInstance = instance;
                                                deleteModal = true;
                                            "
                                        >
                                            Delete
                                        </div>
                                    </div>
                                    <div class="option-button" tabindex="0">
                                        <em
                                            style="
                                                font-size: 25px;
                                                cursor: pointer;
                                            "
                                            class="fe fe-more-vertical"
                                        />
                                    </div>
                                </a-popover>
                            </a-col>
                        </a-row>
                    </template>
					<div style="min-height: 22px;">
						{{ instance.readableId || '' }}
					</div>
                    <a-card-meta
                        :title="instance.name"
                        style="min-height: 24px"
                    >
                    </a-card-meta>
                    <div style="display: inline-block; color: var(--med-gray)">
                        {{
                            instance.allChild && instance.allChild.length
                                ? `${instance.allChild.length} projects linked`
                                : ""
                        }}
                    </div>
                    <div class="mt-4 text-black">
                        {{
                            instance.productType === "lowrise"
                                ? "Low Rise"
                                : "High Rise"
                        }}
                    </div>
                </a-card>
            </div>
        </div>
        <a-modal
            v-if="selectedInstance"
            v-model="deleteModal"
            title="Delete Your Project"
            ok-text="DELETE"
            @ok="onDelete(selectedInstance.id)"
            @cancel="
                selectedInstance = null;
                deleteModal = false;
            "
            :ok-button-props="{ props: { disabled: !deleteAccepted } }"
        >
            <p>
                You are about to delete your project
                <strong> {{ selectedInstance.name }} </strong>. All your data
                will be deleted.
            </p>
            <p>
                <b>Consider downloading your data before deleting a project.</b>
            </p>
            <a-checkbox v-model="downloadData">
                <span>
                    DOWNLOAD DATA (Your data will consist of all your leads and
                    all your completed transactions.)
                    <a-tooltip overlayClassName="change-tooltip-color">
                        <template slot="title">
                            Upon selecting the checkbox an email with a link
                            will be send to you that will allow you to download
                            your data.
                        </template>
                        <a-icon
                            type="question-circle"
                            style="font-size: 12px"
                        />
                    </a-tooltip>
                </span>
            </a-checkbox>
            <br />
            <br />
            <a-checkbox v-model="deleteAccepted">
                I understand that deleting my project can’t be undone and all my
                data will be deleted.
            </a-checkbox>
        </a-modal>
    </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

export default {
    props: {
        instances: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: "",
        },
        page: {
            type: String,
            default: "",
        },
        view: {
            type: String,
            default: "grid",
        },
		loading: {
			type: Boolean,
			default: false
		}
    },
    watch: {
        colourModal: {
            handler(val) {
                if (val.visible) {
                    const color = this.getBackground(val.instance);
                    if (color !== "") {
                        this.selectedColor = color;
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        userSettings() {
            if (
                this.$store.state.user.user.settings != null &&
                Object.keys(this.$store.state.user.user.settings).length != 0
            ) {
                return this.$store.state.user.user.settings;
            } else return {};
        },
        lastVisited() {
            if (
                this.$store.state.user.user &&
                this.$store.state.user.user.settings &&
                this.$store.state.user.user.settings.options &&
                this.$store.state.user.user.settings.options.lastVisited
            ) {
                return this.$store.state.user.user.settings.options.lastVisited;
            } else {
                return {};
            }
        },
        linkedInstances() {
            let linked = [];
            this.storeInstances.forEach((instance) => {
                if (instance.children && instance.children.length !== 0) {
                    if (!linked.includes(instance.id)) {
                        linked.push(instance.id);
                    }
                    instance.children.forEach((child) => {
                        if (
                            typeof child === "Object" &&
                            !linked.includes(child.id)
                        ) {
                            linked.push(child.id);
                        } else if (
                            typeof child !== "Object" &&
                            !linked.includes(child)
                        ) {
                            linked.push(child);
                        }
                    });
                }
            });
            return linked;
        },
        storeInstances() {
            return this.$store.state.instances;
        },
        columns() {
            return [
				{
                    title: "",
                    key: "action",
                    scopedSlots: { customRender: "action" },
					width: "4%",
                },
				{
                    title: "Project Id",
                    key: "readableId",
                    scopedSlots: { customRender: "readableId" },
                    sorter: (a, b) =>
                        (a.readableId || '') <( b.readableId || '')
                            ? -1
                            : (a.readableId || '') >( b.readableId || '')
                            ? 1
                            : 0,
                },
                {
                    title: "Project Name",
                    key: "name",
                    scopedSlots: { customRender: "name" },
                    sorter: (a, b) =>
                        a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
                },
                {
                    title: "Project Type",
                    key: "productType",
                    scopedSlots: { customRender: "productType" },
                    sorter: (a, b) =>
                        a.productType < b.productType
                            ? -1
                            : a.productType > b.productType
                            ? 1
                            : 0,
                },
                {
                    title: "Date Created",
                    key: "createdAt",
                    scopedSlots: { customRender: "createdAt" },
                    sorter: (a, b) =>
                        moment(a.createdAt).format("x") -
                        moment(b.createdAt).format("x"),
                },
                {
                    title: "Lasted Visited",
                    key: "lastVisitedAt",
                    scopedSlots: { customRender: "lastVisitedAt" },
                    sorter: (a, b) =>
                        (this.lastVisited[a.id]
                            ? moment(this.lastVisited[a.id])
                            : 0) -
                        (this.lastVisited[b.id]
                            ? moment(this.lastVisited[b.id])
                            : 0),
                },
                {
                    title: "Owned/Shared",
                    key: "owned/shared",
                    scopedSlots: { customRender: "owned/shared" },
                    sorter: (a, b) => Number(a.own) - Number(b.own),
                },
                {
                    title: "Parent",
                    key: "parent",
                    scopedSlots: { customRender: "parent" },
                    sorter: (a, b) => (a.allChild && a.allChild.length || 0) - (b.allChild && b.allChild.length || 0),
                },
            ];
        },
    },
    data() {
        return {
            colourModal: {
                visible: false,
                instance: {},
            },
            deleteModal: false,
            deleteAccepted: false,
			downloadData:false,
            selectedInstance: null,
            colourList: [
                "#FEC267",
                "#C1BCF4",
                "#89C6E5",
                "#7CDDC1",
                "#FCAFBA",
                "#3A558A",
                "#CD4386",
                "#E089B8",
                "#EF6D8B",
                "#FFDCE6",
                "#227949",
                "#9CB63E",
                "#C7E77A",
                "#18A1B1",
                "#58CAD5",
                "#F3AC46",
                "#FED762",
                "#0A5A93",
                "#83776B",
                "#BEB7AC",
                "#EEDDBF",
                "#F3F1EC",
                "#AE9898",
                "#5AF8E1",
                "#52C4FD",
                "#AB81FC",
                "#C3C3C5",
                "#FD395D",
                "#ACECF9",
                "#FECCAC",
                "#D18063",
                "#917B56",
                "#FDA28F",
                "#7FFD80",
                "#AAD7CF",
                "#D8ECE2",
                "#CBCFE0",
                "#7F7F7F",
                "#B2B2B2",
                "#E5E5E5",
            ],
            selectedColor: "",
            currentExpandedRowKeys: [],
        };
    },
    methods: {
        ...mapActions(["getInstances"]),

        convertDate(num) {
            let x = new Date(num);
            let months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "June",
                "Jul",
                "Aug",
                "Sept",
                "Oct",
                "Nov",
                "Dec",
            ];
            //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
            let year = x.getFullYear();
            let month = x.getMonth() + 1;
            let day = x.getDate();
            let hour = x.getHours();
            let minute = x.getMinutes();
            if (minute.toString().length == 1) {
                minute = "0" + minute.toString();
            }
            return (
                day +
                " " +
                months[month - 1] +
                " " +
                year +
                " " +
                hour +
                ":" +
                minute
            );
        },
        getBackground(instance) {
            const avatar = instance.avatar;
            const id = instance.id;
            let found = this.storeInstances.find((x) => x.id === id);
            if (found) {
                if (found.color) {
                    if (
                        this.userSettings.options &&
                        this.userSettings.options.colour &&
                        this.userSettings.options.colour[id]
                    ) {
                        return this.userSettings.options.colour[id];
                    } else if (
                        !this.userSettings.options ||
                        !this.userSettings.options.colour ||
                        !this.userSettings.options.colour[id] ||
                        this.userSettings.options.colour[id] === ""
                    ) {
                        return found.color;
                    }
                } else {
                    if (
                        this.userSettings.options &&
                        this.userSettings.options.colour &&
                        this.userSettings.options.colour[id]
                    ) {
                        return this.userSettings.options.colour[id];
                    } else if (avatar && typeof avatar === "string") {
                        return avatar;
                    } else return "#FEC267";
                }
            } else return "";
        },

        billingInstance(id) {
            // instance billing details
            this.$router.push(`/settings/billing?instance=${id}`);
        },
        colourInstance(instance) {
            this.colourModal.instance = instance;
            this.colourModal.visible = true;
        },
        settingInstance(id) {
            let found = this.storeInstances.find((x) => x.id === id);
            if (found) {
                this.$store.commit("OPEN_EDIT_INSTANCE", found);
                this.$router.push("/settings/projects/edit");
            }
        },
        handleColorChange() {
            if (this.selectedColor != "") {
                let options = {};
                if (
                    this.userSettings.options &&
                    Object.keys(this.userSettings.options).length != 0 &&
                    this.userSettings != null &&
                    Object.keys(this.userSettings).length != 0
                ) {
                    let storeOptions = JSON.parse(
                        JSON.stringify(this.userSettings.options)
                    );
                    Object.keys(storeOptions).forEach((key) => {
                        options[key] = storeOptions[key];
                    });
                    if (!options.colour) {
                        let colour = {};
                        options.colour = colour;
                        options.colour[this.colourModal.instance.id] =
                            this.selectedColor;
                    } else {
                        options.colour[this.colourModal.instance.id] =
                            this.selectedColor;
                    }
                } else {
                    let colour = {};
                    options.colour = colour;
                    options.colour[this.colourModal.instance.id] =
                        this.selectedColor;
                }
                this.$api
                    .post("/settings", { options: options })
                    .then(({ data }) => {
                        let user = JSON.parse(JSON.stringify(this.user.user));
                        user.settings = data;
                        this.$store.commit("UPDATE_USER", user);
                        this.colourModal.visible = false;
                        this.colourModal.instance = {};
                        this.selectedColor = "";
                    }).catch((err) => console.error(err));
            }
        },

        handleRowExpand(rowkey) {
            if (this.currentExpandedRowKeys.length > 0) {
                let index = this.currentExpandedRowKeys.indexOf(rowkey);
                if (index > -1) {
                    this.currentExpandedRowKeys.splice(index, 1);
                } else {
                    this.currentExpandedRowKeys.splice(
                        0,
                        this.currentExpandedRowKeys.length
                    );
                    this.currentExpandedRowKeys.push(rowkey);
                }
            } else {
                this.currentExpandedRowKeys.push(rowkey);
            }
        },

        onDelete(id) {
            this.$api.delete(`/instances/${id}?downloadData=${this.downloadData}`).then(() => {
                this.getInstances();
            }).catch((err) => console.error(err));
            for (var i = 0; i < this.instances.length; i++) {
                if (this.instances[i].id === id) {
                    this.instances.splice(i, 1);
                }
            }
            this.deleteModal = false;
            this.selectedInstance = null;
        },
    },
};
</script>

<style scoped lang="scss">
.popover-option {
    cursor: pointer;
    height: 35px;
    line-height: 35px;
    padding-left: 10px;
    padding-right: 10px;
}
.popover-option:hover {
    background-color: #faf9f9;
    color: #000;
}
.hexagon {
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
    cursor: pointer;
}
.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon-in2 {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-color: #d0c9d6;
    visibility: visible;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon2 {
    width: 25px;
    height: 50px;
    margin: -20px 0 -20px 0;
}
.icon-option {
    width: 25px;
    height: 100%;
    display: flex;
    align-items: center;
}
.option-button {
    text-align: center;
    height: 25px;
}
.instance-row:hover {
    .hide-hexagon {
        display: none;
    }
    .option-button {
        display: block;
    }
}
.instance-row:not(:hover) {
    .hide-hexagon {
        display: block;
    }
    .option-button {
        display: none;
    }
}
.instance-row:hover {
    .hide-hexagon {
        background-color: var(--off-white-dark);
    }
}

.child {
    background-color: #f7f5f9;
    border: var(--light-gray) solid 1px;
    border-radius: 5px;
    padding: 2px 5px;
}

.instance-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 20px;
    @media screen and (min-width: 768px) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
                0,
                1fr
            );
    }
}
</style>

<style lang="scss" scoped>
.ant-card-actions li span:hover {
    color: var(--black) !important;
}

.change-tooltip-color .ant-tooltip-content .ant-tooltip-inner {
    background: #3f3356;
}

.ant-card-bordered {
    border-radius: 5px;
    box-shadow: #e1dede 1px 1px 5px;
    border: none;
}

.list-view {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: #e1dede 1px 1px 5px;
    border: none;
}

.ant-table-thead > tr > th {
    background-color: #fff;
}
</style>
