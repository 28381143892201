<template>
	<div id="app">
		<BHLoading :show="$store.state.loading" />
		<localization></localization>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import BHLoading from "bh-mod/components/common/Loading";

export default {
	name: 'app',
	components: { Localization, BHLoading },
	computed: {
		...mapGetters(['user'])
	},
	watch: {
		'$route'(to, from) {
			const query = Object.assign({}, to.query)
			this.$store.commit('SETUP_URL_SETTINGS', query)
			if (to.path !== from.path) {
				this.$store.commit('RESET_DEFAULT_FILTER')
			}
		},
	},
}
</script>
<style lang="scss">
.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

#app {
	min-height: 100vh;
}

.top-bar--fixed {
	position: fixed !important;
	top: 0;
	width: 100%;
}

.settings-page {
	padding-top: 100px;

	@media screen and (min-width:768px) {
		padding-top: 130px;
	}
}
</style>
