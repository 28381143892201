<template>
	<div class="settings-page pb-5" :class="$mq == 'sm' ? 'px-2' : 'container container-2'">
		<BHLoading :show="loading" />
		<a-tabs size="large" default-active-key="1">
			<!-- OVERVIEW TAB -->
			<a-tab-pane key="1" :tab="invoiceTab" style="overflow: hidden">
				<div v-if="selectedInvoice">
					<Invoice :userPaymentMethods="userPaymentMethods" :selectedInvoice="selectedInvoice"
						@back="updateSelectedInvoice"/>
				</div>
				<div v-else>
					<Overview :userPaymentMethods="userPaymentMethods" @viewInvoice="updateSelectedInvoice" />
				</div>
			</a-tab-pane>

			<!-- PAYMENT METHOD TAB -->
			<a-tab-pane key="2" tab="Payment Method">
				<a-modal :confirmLoading="modal.load" :title="modal.title" v-model="modal.show" @ok="modal.confirm"
					:okText="modal.okText" :centered="true" @cancel="cancelModal" :width="800">
					<a-form-model v-if="modal.type === 'edit' && selectedEditCard" class="profile" ref="selectedEditCard"
						:model="selectedEditCard">
						<a-row>
							<a-col :span="24">
								<a-row :gutter="16">
									<a-col :span="12">
										<a-form-model-item prop="cardNo" label="Credit Card Number" :rules="req(
											'Please enter the Card Number'
										)
											">
											<a-input :value="'XXXX - XXXX - XXXX - ' +
												selectedEditCard.cardNo.substring(
													12
												)
												" placeholder="0000 - 0000 - 0000 - 0000" size="large" disabled>
											</a-input>
										</a-form-model-item>
									</a-col>
									<a-col :span="12">
										<a-form-model-item prop="cardName" label="Cardholder Name" :rules="req(
											'Please enter the Cardholder Name'
										)
											">
											<a-input v-model="selectedEditCard.cardName
												" placeholder="Cardholder Name" size="large" required>
											</a-input>
										</a-form-model-item>
									</a-col>
								</a-row>

								<a-row :gutter="16">
									<a-col :span="12">
										<a-form-model-item label="Expiry Date" :rules="req(
											'Please enter the Expiry Date'
										)
											" prop="expiry">
											<a-input size="large" v-model="selectedEditCard.expiry
												" placeholder="MM/YY" :maxLength="5" @change="onExpiryUpdate"/>
										</a-form-model-item>
									</a-col>
									<a-col :span="12">
										<a-form-model-item label="CCV/CVV Code" :rules="req(
											'Please enter the CCV/CVV code'
										)
											" prop="cvv">
											<a-input size="large" v-model="selectedEditCard.cvv" placeholder="XXX"
												:maxLength="4" />
										</a-form-model-item>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<hr />
						<a-row>
							<a-col :span="24">
								<a-row :gutter="16">
									<a-col :span="12">
										<a-form-model-item label="Address" prop="address.one" :rules="req('Please enter the Address')
											">
											<a-input v-model="selectedEditCard.address.one
												" placeholder="" size="large" required>
											</a-input>
										</a-form-model-item>
									</a-col>
									<a-col :span="12">
										<a-form-model-item label="City" prop="address.city" :rules="req('Please enter the City')
											">
											<a-input v-model="selectedEditCard.address
													.city
												" placeholder="City" size="large" required>
											</a-input>
										</a-form-model-item>
									</a-col>
								</a-row>

								<a-row :gutter="16">
									<a-col :span="8">
										<a-form-model-item label="Country" prop="address.country" :rules="req('Please enter the Country')">
                                            <a-select
                                                show-search
                                                placeholder="Choose a country"
                                                size="large"
                                                option-filter-prop="children"
                                                :filter-option="filterOption"
                                                v-model="selectedEditCard.address.country"
                                                @change="
                                                    selectedEditCard.address.region = null;
                                                    selectedEditCard.address.zipcode = null;
                                                "
                                            >
                                                <a-select-option value="US">
                                                    United States
                                                </a-select-option>
                                                <a-select-option value="CA">
                                                    Canada
                                                </a-select-option>
                                            </a-select>
										</a-form-model-item>
									</a-col>
									<a-col :span="8">
										<a-form-model-item label="State/Province" :rules="req(
											'Please enter the Province/State'
										)
											" prop="address.region">
											<a-input size="large" v-model="selectedEditCard.address
													.region
												" placeholder="" />
										</a-form-model-item>
									</a-col>
									<a-col :span="8">
										<a-form-model-item label="Zip/Postal Code"
                                            :rules="[
                                                {
                                                    validator: validatePostalCodes,
                                                    trigger: 'change',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Please enter a postal code',
                                                },
                                            ]"
											prop="address.zipcode">
											<a-input size="large" v-model="selectedEditCard.address.zipcode" placeholder="" />
										</a-form-model-item>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
					</a-form-model>
				</a-modal>
				<a-modal :footer="null" :centered="true" :visible="switchCardModal.visible" :width="720"
					@cancel="closeModal">
					<h5 class="mb-3">Remove Payment Method</h5>
					<div class="text-dark mb-5" v-if="Object.keys(switchCardModal.card).length != 0">
						{{
							`Choose a new payment method before removing ${switchCardModal.card.details.ccBrand == "visa"
									? "Visa"
									: switchCardModal.card.details.ccBrand ==
										"mc"
										? "Mastercard"
										: "American Express"
								} ${getCardNumber(
									switchCardModal.card.details.cardNo
								)}`
						}}
					</div>
					<div :style="paymentI == 0 ? 'margin-top:1rem' : ''" style="display: block"
						v-for="(payment, paymentI) in removeCardOption" :key="payment.value + paymentI"
						:value="payment.value">
						<div class="dF aC" style="justify-content: space-between">
							<div class="dF aC">
								<a-radio :checked="switchCardModal.option == payment.value
									" @change="changeRadio" :value="payment.value" />
								<div>
									<div>{{ payment.label }}</div>
									<div @click="updateCard(payment.value)" v-if="paymentI !=
										removeCardOption.length - 1
										" style="
                                            color: var(--orange);
                                            cursor: pointer;
                                        ">
										Update card info
									</div>
								</div>
							</div>
							<i @click="updateCard(payment.value)" v-if="paymentI != removeCardOption.length - 1"
								style="color: var(--orange); cursor: pointer" class="fe fe-edit-2" />
						</div>
						<hr v-if="paymentI != removeCardOption.length - 1" />
					</div>
					<div class="mt-5 dF aC legals">
						By continuing, you agree to the Bildhive&nbsp;
						<a href="https://admin.bildhive.dev/system/terms" target="_">Terms of Service</a>.
					</div>
					<div class="dF aC mt-5 dF jE">
						<a-button class="mr-3" @click="closeModal">CANCEL</a-button>
						<a-button :loading="switchCardModal.loading" @click="removePay" type="primary">SAVE AND
							REMOVE</a-button>
					</div>
				</a-modal>

				<a-card class="mt-3">
					<div :class="$mq != 'sm' ? 'dF' : ''">
						<h5 class="text-dark">Payment Method</h5>
						<div :class="$mq != 'sm' ? 'ml-5' : ''" style="line-height: 27px; color: #9ea0a5">
							You can add your credit card for your future
							purchase and automatically renew
						</div>
					</div>
					<hr style="margin-left: -24px; margin-right: -24px" />
					<a-form class="profile">
						<a-row :gutter="16">
							<a-col :span="7"> Credit Card Number </a-col>
							<a-col :span="5"> Cardholder Name </a-col>
							<a-col :span="4"> Expiry Date </a-col>
							<a-col :span="5"> Address </a-col>
						</a-row>
						<hr v-if="!userPaymentMethods.length" style="margin-left: -24px; margin-right: -24px" />
						<div v-for="card in userPaymentMethods" :key="card.id">
							<hr style="margin-left: -24px; margin-right: -24px" />
							<a-row :gutter="16">
								<a-col :span="7" class="dF" style="align-items: start">
									<img v-if="card.details.ccBrand === 'visa'" class="mr-3" src="@/assets/visa.svg"
										width="60px" alt="visa" />
									<img v-else-if="card.details.ccBrand === 'mc'
										" class="mr-3" src="@/assets/mc.svg" width="60px" alt="mc" />
									<img v-else-if="card.details.ccBrand === 'amex'
										" class="mr-3" src="@/assets/amex.svg" width="60px" alt="amex" />
									<div v-else class="mr-3" style="
                                            object-fit: contain;
                                            width: 60px;
                                            height: 38px;
                                        " />
									<div>
										<div>
											{{
												cardNumberDetails(card.details)
											}}
										</div>
										<div class="mt-2" style="font-weight: bold">
											By continuing, you agree to the
											Bildhive
											<router-link to="/system/terms#payment" target="_blank"
												class="text-med-gray">Payments Terms</router-link>
										</div>
									</div>
								</a-col>
								<a-col :span="5" style="font-weight: bold">
									<div style="
                                            width: 100%;
                                            height: 100%;
                                            display: flex;
                                        ">
										<div>{{ card.details.cardName }}</div>
									</div>
								</a-col>
								<a-col :span="4" :style="checkExpiry(card)
										? 'color: var(--danger);'
										: ''
									" style="color: #9ea0a5">
									{{
										!checkExpiry(card)
										? card.details.expiry
										: "Expired"
									}}
								</a-col>
								<a-col :span="5">
									{{ card.details.address.one }}
								</a-col>
								<a-col :span="2" class="dF">
									<div class="mr-4 edit-delete" @click="editCard(card)">
										<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
											fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
											stroke-linejoin="round" class="feather feather-edit-2">
											<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
										</svg>
									</div>
									<div class="edit-delete" @click="deleteCard(card)">
										<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
											fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
											stroke-linejoin="round" class="feather feather-trash-2">
											<polyline points="3 6 5 6 21 6"></polyline>
											<path
												d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
											</path>
											<line x1="10" y1="11" x2="10" y2="17"></line>
											<line x1="14" y1="11" x2="14" y2="17"></line>
										</svg>
									</div>
								</a-col>
							</a-row>
						</div>
					</a-form>

					<billingmodal :show="newCard" :load="modal.load" :modalTitle="'Add New Card'" :modalOkText="'Add Card'"
						@close="newCard = false" @cardPayment="preSubmit"></billingmodal>

					<a-button type="primary" icon="plus" class="mt-4 pull-right" @click="
						newCard = true;
					modal.load = false;
					">
						ADD PAYMENT METHOD
					</a-button>
				</a-card>
			</a-tab-pane>

		</a-tabs>
	</div>
</template>
<script>
import { mapState } from "vuex";
import valid from "card-validator";
import billingmodal from "@/views/instance/billingmodal";
import Overview from "@/components/common/Overview";
import Invoice from "@/views/settings/invoice";
import BHLoading from "bh-mod/components/common/Loading";
import postalCodes from "postal-codes-js";

export default {
	components: {
		billingmodal,
		Overview,
		Invoice,
		BHLoading
	},
	data() {
		return {
			loading: false,
			showCharges: [],
			selectedInvoice: null,
			numberValidation: {
				card: {
					niceType: "",
					type: "",
					patterns: [4],
					gaps: [4, 8, 12],
					lengths: [16, 18, 19],
					code: { name: "CVV", size: 3 },
					matchStrength: 1,
				},
				isPotentiallyValid: false,
				isValid: false,
			},
			Address: {
				street_number: "",
				route: "",
				locality: "",
				country: "",
				postal_code: "",
				latitude: 0,
				longitude: 0,
			},
			userPaymentMethods: [],
			paymentMethod: {
				ccBrand: "",
				cardNo: "",
				expiry: "",
				cardname: "",
				cvv: "",
				address: {
					one: "",
					two: "",
					region: "",
					city: "",
					country: "",
					zipcode: "",
				},
			},
			editPayment: {},
			newCard: false,
			selectedCard: {},
			selectedEditCard: {},
			switchCardModal: {
				card: {},
				visible: false,
				option: "",
				loading: false,
			},
			modal: {
				show: false,
				title: "Edit Payment Method",
				load: false,
				type: "",
				okText: "Update",
				confirm: () => console.log("confirm"),
			}
		};
	},
	watch: {
		paymentMethod: {
			handler(newCard) {
				let { cardNo } = newCard;
				let nums = valid.number(cardNo);
				this.numberValidation = nums;
			},
			deep: true,
		},
		modal: {
			handler(val) {
				if (!val.show) {
					this.numberValidation = {
						card: {
							niceType: "",
							type: "",
							patterns: [4],
							gaps: [4, 8, 12],
							lengths: [16, 18, 19],
							code: { name: "CVV", size: 3 },
							matchStrength: 1,
						},
						isPotentiallyValid: false,
						isValid: false,
					};
					this.Address = {
						street_number: "",
						route: "",
						locality: "",
						country: "",
						postal_code: "",
						latitude: 0,
						longitude: 0,
					};
					this.paymentMethod = {
						ccBrand: "",
						cardNo: "",
						expiry: "",
						cardName: "",
						cvv: "",
						address: {
							one: "",
							two: "",
							region: "",
							city: "",
							country: "",
							zipcode: "",
						},
					};
				}
			},
			deep: true,
		},
		numberValidation: {
			handler(newCard) {
				let table = {
					express: "amex",
					mastercard: "mc",
					visa: "visa",
				};
				if (
					newCard.isPotentiallyValid &&
					newCard.card?.niceType
				) {
					let ccBrand = newCard.card.niceType.toLowerCase();
					let foundKey = Object.keys(table).find((x) =>
						ccBrand.includes(x)
					);
					if (foundKey) {
						let filename = table[foundKey];
						this.paymentMethod.ccBrand = filename;
						this.numberValidation.src = require(`@/assets/cc/${filename}.png`);
					} else {
						this.paymentMethod.ccBrand = "";
						this.numberValidation.src = require(`@/assets/cc/error.png`);
					}
				} else {
					this.numberValidation.src = require(`@/assets/cc/error.png`);
				}
			},
			deep: true,
		},
	},
	computed: {
		...mapState(["user"]),

		removeCardOption() {
			let options = [];
			if (
				this.userPaymentMethods &&
				this.userPaymentMethods.length != 0 &&
				this.switchCardModal &&
				this.switchCardModal.card &&
				Object.keys(this.switchCardModal.card).length != 0
			) {
				this.userPaymentMethods.forEach((pay) => {
					let obj = {};
					if (pay.details.ccBrand == "visa") obj.name = "Visa";
					else if (pay.details.ccBrand == "mc")
						obj.name = "Mastercard";
					else if (pay.details.ccBrand == "amex")
						obj.name = "American Express";
					obj.label = `${obj.name} ${this.getCardNumber(
						pay.details.cardNo
					)}`;
					obj.value = pay.id;
					if (obj.value != this.switchCardModal.card.id)
						options.push(obj);
				});
				let newCard = {
					label: "Add credit or debit card",
					value: "newCard",
				};
				options.push(newCard);
			}
			return options;
		},

		invoiceTab() {
			if (this.$route.query.instance) {
				return 'Project Invoices'
			}
			return 'All Invoices'
		}
	},
	async created() {
		this.$store.commit('CLOSE_FILTER')
		this.fetchPaymentMethods();
	},
	methods: {
        filterOption(input, option) {
            return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0);
        },

		async fetchPaymentMethods() {
			this.$api.get("/payment-methods").then(({ data }) => {
				this.userPaymentMethods = Object.values(data);
			}).catch((err) => console.error(err));
		},
		updateSelectedInvoice(value = null) {
			this.selectedInvoice = value;
		},

		updateCard(id) {
			let found = this.userPaymentMethods.find((x) => x.id == id);
			if (found) {
				this.editCard(found);
			}
		},
		removePay() {
			if (this.switchCardModal.option == "")
				return this.$message.error("Please select an option.");
			else if (this.switchCardModal.option == "newCard")
				this.newCard = true;
			else {
				this.switchCardModal.loading = true;
				this.$api
					.put(
						`/payment-methods/${this.switchCardModal.card.id}/switch`,
						{ id: this.switchCardModal.option }
					)
					.then(({ data }) => {
						let index = this.userPaymentMethods.findIndex(
							(x) => x.id == data.id
						);
						if (index != -1) this.userPaymentMethods.splice(index, 1);
						this.switchCardModal.loading = false;
						this.closeModal();
					}).catch((err) => console.error(err));
			}
		},
		changeRadio(e) {
			this.switchCardModal.option = e.target.value;
		},
		getCardNumber(string) {
			let card = string.substring(string.length - 4, string.length);
			return `****${card}`;
		},
		req: (msg) => ({ required: true, message: msg }),

		checkExpiry(obj) {
			let data = obj.details.expiry;
			let expired = false;
			var date = new Date();
			let year = date.getFullYear().toString().substring(2, 4);
			let month = date.getMonth() + 1;

			if (Number(data.substring(3, 5)) <= Number(year)) {
				if (Number(data.substring(0, 2)) < month) {
					expired = true;
				}
			}

			return expired;
		},

		cardNumberDetails(card) {
			let cardNo = card.cardNo;
			let cardType = "";

			if (card.ccBrand == "visa") {
				cardType = "Visa";
			} else if (card.ccBrand == "mc") {
				cardType = "MasterCard";
			} else if (card.ccBrand == "amex") {
				cardType = "AmericanExpress";
			}

			return (
				cardType + " ending in " + cardNo.substring(cardNo.length - 4)
			);
		},
		preSubmit(data) {
			// is it a new payment method
			//then create payment method then run submit()

			// else just run submit
			this.paymentMethod = data;

			let expiry = valid.expirationDate(this.paymentMethod.expiry);
			let sendCardOBJ = JSON.parse(JSON.stringify(this.paymentMethod));
			let { month, year } = expiry;
			sendCardOBJ.expiry = `${month}/${year}`;
			self = this;
			this.modal.load = true;
			this.$api
				.post("/payment-methods", {
					details: sendCardOBJ,
					default: sendCardOBJ.default || false
				})
				.then(({ data }) => {
					if (data.success == false) {
						self.$message.error("Card already exists!");
						self.modal.load = false;
						self.newCard = false;
					} else {
						this.$message.success('Payment method added successfully!');
						self.userPaymentMethods.push(data);
						self.modal.load = false;
						self.newCard = false;
					}
				})
				.catch((err) => {
					console.error("err", err);
					//temporary fix for the error
					self.modal.load = false;
					self.newCard = false;
				});
		},

		closeModal() {
			this.switchCardModal.visible = false;
			this.switchCardModal.card = {};
			this.switchCardModal.option = "";
		},
		deleteCard(card) {
			this.selectedCard = card;
			if (card.instances && card.instances.length > 0) {
				this.switchCardModal.visible = true;
				this.switchCardModal.card = card;
			} else if (!card.instances || card.instances.length == 0) {
				let self = this;
				this.$confirm({
					title: "Delete Payment Method",
					content: (h) => <div>Do you want to delete this card?</div>,
					okText: "DELETE",
					okType: "danger",
					cancelText: "CANCEL",
					centered: true,
					onOk() {
						self.confirmDelete(self.selectedCard);
					},
					onCancel() {
						console.log("Cancel");
					},
				});
			}
		},
		confirmDelete(item) {
			this.$api
				.delete(`/payment-methods/${item.id}`)
				.then(({ data }) => {
					this.userPaymentMethods = this.userPaymentMethods.filter(
						(x) => x.id !== data.id
					);
				})
				.catch((err) => {
					this.$message.error(this.$err(err));
				});
		},

		editCard(item) {
			this.modal.confirm = this.doEditCard;
			this.modal.type = "edit";
			// this.modal.okText = 'Update Details'
			this.modal.show = true;
			this.editPayment = item;
			this.selectedEditCard = {
				ccBrand: "",
				cardNo: "",
				expiry: "",
				cardName: "",
				cvv: "",
				address: {
					one: "",
					two: "",
					region: "",
					city: "",
					country: "",
					zipcode: "",
				},
			};
			this.selectedEditCard.ccBrand = item.details.ccBrand;
			this.selectedEditCard.cardNo = item.details.cardNo;
			this.selectedEditCard.expiry = item.details.expiry;
			this.selectedEditCard.cardName = item.details.cardName;
			this.selectedEditCard.cvv = item.details.cvv;
			this.selectedEditCard.address.one = item.details.address.one;
			this.selectedEditCard.address.two = item.details.address.two;
			this.selectedEditCard.address.region = item.details.address.region;
			this.selectedEditCard.address.city = item.details.address.city;
			this.selectedEditCard.address.country =
				item.details.address.country;
			this.selectedEditCard.address.zipcode =
				item.details.address.zipcode;
		},
		doEditCard() {
			this.editPayment.details = this.selectedEditCard;

			this.$refs.selectedEditCard.validate((valid2) => {
				if (valid2) {
					let expiry = valid.expirationDate(
						this.selectedEditCard.expiry
					);

					if (!expiry.isValid)
						return this.$message.error(
							"Card Expiry not Valid. Please try again."
						);

					this.modal.load = true;

					this.$api
						.put(
							`/payment-methods/${this.editPayment.id}`,
							this.editPayment
						)
						.then(async ({ data }) => {
							this.userPaymentMethods.forEach((pm, index) => {
								if (pm.id === data.id) {
									this.userPaymentMethods[index] = data;
								}
							});
							await this.fetchPaymentMethods();
							this.modal.load = false;
							this.modal.show = false;
						}).catch((err) => console.error(err));
				} else {
					return false;
				}
			});
		},
		cancelModal() {
			this.modal.show = false;
			this.$refs.selectedEditCard.resetFields();
		},

        onExpiryUpdate(event) {
            // also remove any character that is not a number
            let expiry = event.target.value.replace(/\D/g, '');
            if (expiry.length > 2) {
                expiry = expiry.slice(0, 2) + '/' + expiry.slice(2);
            }
            this.selectedEditCard.expiry = expiry;
        },

        validatePostalCodes(rule, value, callback) {
            if (value === "") {
                callback(new Error("Please enter a Zip/Postal Code"));
            } else {
                if (!this.selectedEditCard.address.country) {
                    callback(new Error("Please select a country first"));
                }
                const countryCode = this.selectedEditCard.address.country;
                const result = postalCodes.validate(countryCode, value);
                if (result === true) {
                    callback();
                } else {
                    callback(new Error(result));
                }
            }
        },
	},
};
</script>

<style scoped>
.edit-delete {
	cursor: pointer;
	color: rgba(0, 0, 0, 0.65);
	transition: color 0.1s ease-in;
}

.edit-delete:hover {
	color: orange;
	transition: color 0.1s ease-in;
}

.dropdown {
	cursor: pointer;
}

.paid-button {
	line-height: 2.2;
	position: relative;
	display: inline-block;
	font-weight: 400;
	white-space: nowrap;
	text-align: center;
	background-image: none;
	border: 1px solid transparent;
	height: 32px;
	width: 80px;
	padding: 0 15px;
	font-size: 14px;
	border-radius: 4px;
	border-color: #d9d9d9;
	background-color: var(--success);
	color: white;
	user-select: none;
}
</style>

<style lang="scss">
.billing-table .ant-table table {
	border-left: 1px solid #e4e7eb;
	border-right: 1px solid #e4e7eb;
	border-top: 1px solid #e4e7e8;
	border-radius: 0;
}

.billing-table {
	width: 100%;
}

.billing-table .ant-table-thead>tr>th {
	background: none;
}

.billing-table .ant-table .ant-table-title {
	border: 1px solid #e4e7e8;
	padding: 16px 16px;
}

.container-2 {
	max-width: 100vw;
	margin-left: 0;
	margin-right: 0;
	padding-right: 25px;
	padding-left: 25px;
}

.billing-table .ant-table {
	background-color: white;
}

.legals {
	a {
		color: var(--primary);
	}
}
</style>
