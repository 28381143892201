var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"invoices"},[_c('FilterDrawer'),(_vm.instanceId && !_vm.loading)?_c('div',{staticClass:"py-2",staticStyle:{"font-size":"18px"}},[_c('a-icon',{staticClass:"mr-3",attrs:{"type":"arrow-left"},on:{"click":function($event){return _vm.$router.back()}}}),_c('strong',[_vm._v(_vm._s(_vm.selectedInstanceName))])],1):_vm._e(),_c('a-card',{staticClass:"mt-3",attrs:{"title":null}},[_c('div',{staticClass:"dF aC jSB"},[_c('div',{staticClass:"dF aC jSB"},[_c('div',{staticClass:"text-md"},[_vm._v("Summary")]),_c('div',{staticClass:"ml-5",staticStyle:{"color":"#9ea0a5"}},[_vm._v(" "+_vm._s(`${_vm.totalInvoices} total`)+" ")])]),_c('div',{staticClass:"dF aC",staticStyle:{"gap":"30px"}},[_c('div',[_c('a-input',{attrs:{"placeholder":"Search your projects...","size":"large","allowClear":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('a-tooltip',{attrs:{"slot":"prefix","title":"Search your projects","overlayClassName":"change-tooltip-color"},slot:"prefix"},[_c('a-icon',{staticStyle:{"color":"black"},attrs:{"type":"search"}})],1)],1)],1),_c('div',{staticClass:"dF aC px-3 py-2 ml-3",staticStyle:{"background-color":"white","border-radius":"4px","border":"1px solid #ECEBED","cursor":"pointer"},on:{"click":function($event){return _vm.$store.commit('OPEN_FILTER')}}},[_c('div',{staticClass:"mr-2"},[_vm._v("Filters")]),_c('i',{staticClass:"fe fe-filter"})])])]),_c('hr',{staticStyle:{"margin-left":"-24px","margin-right":"-24px"}}),_c('a-table',{staticClass:"white-table",attrs:{"rowKey":(e, i) => i,"columns":_vm.columns,"dataSource":_vm.invoices,"loading":_vm.loading,"pagination":{
                    current: _vm.currentPage,
                    total: _vm.totalInvoices,
                    pageSize: _vm.pageSize,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '30', '40', '50', '75', '100']
                },"scroll":{ x: 1200 }},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"readableId",fn:function(invoice){return _c('div',{staticClass:"text-dark",staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(invoice.instance?.readableId || '')+" ")])}},{key:"instance",fn:function(invoice){return _c('div',{staticClass:"text-dark",staticStyle:{"font-size":"15px","min-width":"100px"}},[_vm._v(" "+_vm._s(invoice.instance?.name)+" ")])}},{key:"dueDate",fn:function(invoice){return _c('div',{staticStyle:{"color":"#9ea0a5"}},[_vm._v(" "+_vm._s(invoice.validUntil ? _vm.$formatDate(+invoice.validUntil) : "N/A")+" ")])}},{key:"invoiceDate",fn:function(invoice){return _c('div',{staticStyle:{"color":"#9ea0a5","min-width":"150px"}},[_vm._v(" "+_vm._s(invoice.createdAt ? _vm.$formatDate(invoice.createdAt) : "N/A")+" ")])}},{key:"invoiceNumber",fn:function(invoice){return _c('div',{},[_vm._v(" "+_vm._s(invoice.invoiceNumber || "N/A")+" ")])}},{key:"projectStatus",fn:function(invoice){return _c('div',{staticClass:"text-center"},[(invoice.instance)?_c('div',[(invoice.instance.deleted)?_c('span',[_vm._v("Deleted")]):(invoice.instance.susspended)?_c('span',[_vm._v(" Locked ")]):_c('span',[_vm._v(" Active")])]):_c('div',[_vm._v(" Active ")])])}},{key:"total",fn:function(invoice){return _c('div',{staticClass:"text-dark",staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(!invoice ? "$0" : `$${invoice.total}`)+" ("+_vm._s(invoice.currency || 'USD')+") ")])}},{key:"transaction",fn:function(invoice){return _c('div',{},[(invoice.paymentMode !== 'offline')?_c('div',{staticClass:"dF aC",staticStyle:{"gap":"10px"}},[_c('img',{attrs:{"alt":"no-card","src":_vm.getTransactionDetails(invoice).imageUrl,"width":"40%","height":"40%"}}),_vm._v(" "+_vm._s(_vm.getTransactionDetails(invoice).number)+" ")]):_vm._e()])}},{key:"status",fn:function(invoice){return _c('div',{},[(invoice)?_c('span',{staticClass:"py-1 px-2 paid-button",style:(invoice.paid
						? 'background-color:#28C791'
						: 'background-color:#FD647C')},[_vm._v(" "+_vm._s(invoice.paid ? "PAID" : "UNPAID")+" ")]):_c('span',[_vm._v("N/A")])])}},{key:"refundTotal",fn:function(invoice){return _c('div',{},[(invoice.refunds)?_c('span',[_vm._v(" "+_vm._s(_vm.calculateTotalRefund(invoice.refunds))+" ("+_vm._s(invoice.currency || "USD")+") ")]):_vm._e()])}},{key:"refundStatus",fn:function(invoice){return _c('div',{},[_c('span',{staticClass:"paid-button py-1 px-2",style:(invoice.refundStatus
						? invoice.refundStatus === 'partial'
							? 'background-color:#ffc107'
							: 'background-color: var(--primary)'
						: '')},[_c('a-tooltip',{attrs:{"overlayClassName":"change-tooltip-color"}},[(invoice.refunds?.length)?_c('template',{slot:"title"},_vm._l((invoice.refunds),function(r){return _c('div',{key:r.id},[_c('p',[_vm._v(" "+_vm._s(r.reason || 'N/A')+": $"+_vm._s(r.amount)+"("+_vm._s(invoice.currency || "USD")+") ")])])}),0):_vm._e(),_vm._v(" "+_vm._s(invoice.refundStatus ? invoice.refundStatus === "partial" ? "PARTIAL" : "FULL" : "")+" ")],2)],1)])}},{key:"operation",fn:function(invoice){return _c('div',{},[(invoice)?_c('div',{staticClass:"dF aC jSB",staticStyle:{"gap":"5px"}},[_c('a-button',{attrs:{"type":"primary","ghost":""},on:{"click":function($event){return _vm.$emit('viewInvoice', invoice)}}},[_vm._v(" VIEW INVOICE ")]),_c('a-button',{attrs:{"disabled":!(!invoice.paid && invoice.paymentMode !== 'offline' && _vm.user.id === invoice.instance.billingOwner.id)},on:{"click":function($event){return _vm.payAgain(invoice)}}},[_vm._v(" PAY ")]),_c('a-button',{staticStyle:{"padding":"0px"},attrs:{"type":"link","icon":"download","size":"small"},on:{"click":function($event){return _vm.printInvoice(invoice, false);}}},[_vm._v(" PDF ")]),_c('a-button',{staticStyle:{"padding":"0px"},attrs:{"type":"link","icon":"eye","size":"small"},on:{"click":function($event){return _vm.printInvoice(invoice, true);}}},[_vm._v(" PDF ")])],1):_vm._e()])}}])})],1),_c('div',[_c('InvoicePDF',{attrs:{"invoice":_vm.invoiceToDownload,"userPaymentMethods":_vm.userPaymentMethods,"previewModal":_vm.previewModal},on:{"close":function($event){_vm.invoiceToDownload = null; _vm.previewModal = false;}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }