<template>
	<div v-if="filterDrawer.visible">
		<a-drawer :zIndex="1000" :width="500" @close="onClose" :visible="filterDrawer.visible"
			:wrapStyle="{ overflow: 'auto', }">
			<bhLoading :show="loading" />
			<div class="h-full dF fC jSB" style="margin-bottom: 60px;" v-if="this.filter && Object.keys(this.filter).length">
				<div class="f1">
					<h5>Filters</h5>
					<div v-show="filterApplied" style="display:inline-block">
						<div @click="clearFilter" class="dF aC mt-3 py-2 px-4"
							style="border-radius:4px; border:1px solid #ECEBED; background-color:white; cursor:pointer">
							<div class="mr-3">Clear Filter</div><a-icon style="color:var(--danger)" type="delete" />
						</div>
					</div>
					<div class="mt-5">
						<div class="mt-3">
							<h6>Invoice Price:</h6>
							<a-slider @change="changePriceRange" :default-value="filter.price" range :min="0"
								:max="maxInvoicePrice" />
							<a-row :gutter="16">
								<a-col :span="10">
									<div style="border-bottom: 1px solid #000">
										${{ filter.price && filter.price[0] && filter.price[0].toLocaleString("US") }}
									</div>
								</a-col>
								<a-col :span="4">
									<div style="text-align: center">-</div>
								</a-col>
								<a-col :span="10">
									<div style="border-bottom: 1px solid #000">
										${{ filter.price && filter.price[1] && filter.price[1].toLocaleString("US") }}
									</div>
								</a-col>
							</a-row>
						</div>
						<div class="mt-5">
							<a-row :gutter="16">
								<a-col :span="10">
									Invoice Start Date:
									<a-date-picker v-model="filter.timeFrame[0]" format="DD-MM-YYYY"
										@change="e => dateChange(e, 0)" />
								</a-col>
								<a-col :span="4">
									<div style="text-align: center">-</div>
								</a-col>
								<a-col :span="10">
									Invoice End Date:
									<a-date-picker v-model="filter.timeFrame[1]" format="DD-MM-YYYY"
										@change="e => dateChange(e, 1)" />
								</a-col>
							</a-row>
						</div>
						<div class="mt-5">
							<h6>Project Type:</h6>
							<a-row :gutter="[16, 16]">
								<a-col :span="6" v-for="(productType, index) in projectTypeList" :key="index"
									:value="productType.value">
									<div @click="projectTypeChange(productType.value)"
										:style="filter.productType && filter.productType.length && filter.productType.includes(productType.value) ? 'background-color: #CAC3D0;' : ''"
										class="py-1 px-2 filter-options"
										style=" border: #d0c9d6 1px solid; border-radius: 6px; width: 100%; cursor: pointer; text-align: center; ">
										{{ productType.name }}
									</div>
								</a-col>
							</a-row>
						</div>
						<div class="mt-5">
							<h6>Invoice Status:</h6>
							<a-row :gutter="[16, 16]">
								<a-col :span="6" v-for="(status, index) in statusList" :key="index" :value="status.value">
									<div @click="statusChange(status.value)"
										:style="filter.status && filter.status.length && filter.status.includes(status.value) ? 'background-color: #CAC3D0;' : ''"
										class="py-1 px-2 filter-options"
										style=" border: #d0c9d6 1px solid; border-radius: 6px; width: 100%; cursor: pointer; text-align: center; ">
										{{ status.name }}
									</div>
								</a-col>
							</a-row>
						</div>
						<div class="mt-5">
							<h6>Show Deleted Project:</h6>
							<a-row :gutter="[16, 16]">
								<a-col>
									<a-switch v-model="filter.showDelete" />
								</a-col>
							</a-row>
						</div>
						<div class="mt-5">
							<h6>Only Show Latest Project Invoice:</h6>
							<a-row :gutter="[16, 16]">
								<a-col>
									<a-switch v-model="filter.showLatestOnly" />
								</a-col>
							</a-row>
						</div>
					</div>
				</div>
				<div
					style="position: absolute; left: 0px; bottom: 0px; width: 100%; border-top: 1px solid rgb(233, 233, 233); padding: 10px 16px; background: rgb(255, 255, 255); text-align: right;">
					<div class="dF jE">
						<div class="dF ml-3">
							<a-button size="large" class="mr-3 cancel-button" @click="onClose">CANCEL</a-button>
							<a-button size="large" @click="submit" type="primary">APPLY FILTER</a-button>
						</div>
					</div>
				</div>
			</div>
		</a-drawer>
	</div>
</template>

<script>
import moment from 'moment'
import bhLoading from 'bh-mod/components/common/Loading'
export default {
	components: { bhLoading },
	data() {
		return {
			tagSource: [],
			dateRange: undefined,
			loading: false,
			displayRange: '',
			filter: {},
			projectTypeList: [{ name: 'Any', value: 'any' }, { name: 'High Rise', value: 'highrise' }, { name: 'Low Rise', value: 'lowrise' }],
			statusList: [{ name: 'Any', value: 'any' }, { name: 'Paid', value: 'paid' }, { name: 'Unpaid', value: 'unpaid' }]
		}
	},
	watch: {
		visible: {
			handler(val) {
				if (val) {
					this.filter = JSON.parse(JSON.stringify(this.filterDrawer.filter))
				}
			}
		}
	},
	computed: {
		maxInvoicePrice() {
			return 100000;
		},
		filterDrawer() {
			return this.$store.state.filterDrawer
		},
		visible() {
			return this.filterDrawer.visible
		},
		filterApplied() {
			let filter = this.filterDrawer.filter;
			if (filter.productType[0] !== "any" || filter.status[0] !== "any" || filter.showDelete || filter.showLatestOnly || (filter.price && filter.price[0] !== 0) || (filter.price && filter.price[1] !== 0) || filter.timeFrame[0] !== null || filter.timeFrame[1] !== null) {
				return true
			}
			return false
		}
	},
	methods: {
		moment,

		submit() {
			this.$store.commit("UPDATE_FILTER", this.filter);
			this.onClose();
		},

		clearFilter() {
			this.$store.commit('RESET_DEFAULT_FILTER')
			this.$store.commit('CLOSE_FILTER')
		},
		onClose() {
			this.$store.commit('CLOSE_FILTER')
		},

		projectTypeChange(productType) {
			if (productType != "any") {
				if (
					this.filter.productType.length == 1 &&
					this.filter.productType[0] == "any"
				) {
					this.filter.productType.splice(0, 1);
				}
				if (!this.filter.productType.includes(productType)) {
					this.filter.productType.push(productType);
				} else {
					let index = this.filter.productType.findIndex((x) => x == productType);
					this.filter.productType.splice(index, 1);
				}
			} else {
				this.filter.productType = ["any"];
			}
			if (this.filter.productType.length == 0) {
				this.filter.productType = ["any"];
			}
		},

		statusChange(status) {
			if (status != "any") {
				if (
					this.filter.status.length == 1 &&
					this.filter.status[0] == "any"
				) {
					this.filter.status.splice(0, 1);
				}
				if (!this.filter.status.includes(status)) {
					this.filter.status.push(status);
				} else {
					let index = this.filter.status.findIndex((x) => x == status);
					this.filter.status.splice(index, 1);
				}
			} else {
				this.filter.status = ["any"];
			}
			if (this.filter.status.length == 0) {
				this.filter.status = ["any"];
			}
		},

		changePriceRange(e) {
			this.filter.price = e || [0, 0];
		},

		dateChange(date, index) {
			this.filter.timeFrame[index] = date || null;
		}
	}
}
</script>

<style>
.range-display .ant-tag-blue {
	color: black !important;
}

.collapse-display.ant-collapse {
	border: none !important;
}

.collapse-display.ant-collapse>.ant-collapse-item {
	border-bottom: none !important;
}

.collapse-display .ant-collapse-header {
	padding: none !important;
}

.collapse-display.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
	padding: 0 !important;
}

.collapse-display .ant-collapse-content {
	border: none !important;
}

.cancel-button.ant-btn:hover {
	border-color: #ECE9F1 !important;
}
</style>
<style scoped>
.filter-options:hover {
	background-color: #ECE9F1;
}

.hide-display {
	height: 0;
	transition: height .3s ease-in-out;
	overflow: hidden;
}

.show-display {
	height: 200px;
}
</style>
