<template>
    <div class="f1 apps-page">
        <div v-if="appsGrouped.length === 0" class="f1 relative dF fC">
            <div class="dF jC fC aC p-3 h-full">
                <div class="dF jC fC aC pt-15">
                    <img
                        src="../../assets/Empty_Design.png"
                        style="width: 500px; max-width: 80vw; min-width: 200px"
                        alt="No Access"
                    />
                    <p class="mt-8 text-center">
                        <span class="text-lg fw-300"
                            >No access has been granted, contact owner/admin for
                            access to applications.</span
                        >
                    </p>
                </div>
            </div>
        </div>
        <div v-else>
            <marketplace />
            <div
                style="align-items: center; display: block !important"
                class="px-5 py-4"
            >
                <h3>Workplace</h3>
                <a-breadcrumb>
                    <a-breadcrumb-item>{{ instance.name }}</a-breadcrumb-item>
                    <a-breadcrumb-item style="color: var(--orange)"
                        >Workplace</a-breadcrumb-item
                    >
                </a-breadcrumb>
            </div>
            <div class="apps-cats-wrapper px-5">
                <template v-for="(cat, catI) in appsGrouped">
                    <div v-if="cat && cat.name" :key="catI">
                        <h3 v-if="cat.name !== 'default'">{{ cat.name }}</h3>
                        <div class="cat-apps-wrapper">
                            <appcards
                                v-for="(app, appI) in cat.apps"
                                :key="`cat${catI}${appI}`"
                                :link="`https://${app.subdomain}.bildhive.${tld}`"
                                :img="getAppIcon(app.media)"
                                :name="app.name"
                                :date="app.updatedAt"
                                :id="app.id"
                                :category="cat.name"
                            >
                            </appcards>
                        </div>
                        <hr v-if="catI != appsGrouped.length - 1" />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { getInitial } from "bh-mod";
import appcards from "@/views/apps/appcards";
import marketplace from "./marketplace";

export default {
    components: { appcards, marketplace },
    data() {
        return {
            tld:
                window.location.href.includes("localhost") ||
                window.location.href.includes(".dev")
                    ? "dev"
                    : "com",
            modalApp: {},
        };
    },
    watch: { },
    methods: {
        getInitial,
        getAppIcon(media) {
            return media.icons.svg || media.icons.png;
        }
    },
    computed: {
        instance() {
            return this.$store.state.instance || {};
        },
        appsGrouped() {
            let apps = this.instance.apps || [];
            let { permissions = {} } = this.instance;
            let permission = Object.keys(permissions);

            if (!permissions.admin) {
                apps = apps.filter(app =>
                    permission.includes(app.subdomain) && permissions[app.subdomain] > 0
                );
            }

            let cats = apps.reduce((acc, app) => {
                let cat = app.media.category || "Apps";
                if (!acc[cat]) acc[cat] = { name: cat, apps: [] };
                acc[cat].apps.push(app);
                return acc;
            }, {});

            // Sort category apps based on name
            Object.values(cats).forEach(category => {
                category.apps.sort((a, b) => a.name.localeCompare(b.name));
            });

            let ordered = [
                cats["default"],
                cats["Administration & Team Management"],
                cats["Project Setup & Configuration"],
                cats["Project Configuration"],
                cats["Product Management"],
                cats["Marketing & Communications Management"],
                cats["Customer & Transaction Management"]
            ].filter(Boolean);

            if (cats["Customer & Transaction Management"] && !this.$store.getters.isWorksheetActive) {
                cats["Customer & Transaction Management"].apps = cats["Customer & Transaction Management"].apps.filter(a => a.subdomain !== 'worksheets');
            }

            return ordered;
        }
    },
	created() {
		if (!this.instance?.name) {
			return this.$router.push('/select')
		}
	}
};
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
    .apps-page-leftbar {
        display: none !important;
    }
}

.cat-apps-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}
.apps-cats-wrapper {
    margin-top: calc(1.3vw + 10px);
    h3 {
        font-size: 22px;
        margin-bottom: 20px;
    }
}
.hive-cat-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
}
.app-store-tabs {
    .ant-tabs-bar {
        background: transparent;
        border-color: transparent;
    }
    .ant-tabs-nav-wrap {
        // background: linear-gradient(180deg, var(--off-white-dark), transparent);
        background: transparent;
        padding-bottom: 20px;
    }
    .ant-tabs-nav-scroll {
        display: flex;
        justify-content: center;
    }
}
.app-modal {
    .ant-modal-header {
        padding: 0;
    }
    .app-icon {
        position: absolute;
        top: 0;
        left: 0;
        // transform: translate3d(-50%,-50%,0);
        width: 100px;
        height: 100px;
    }
    [aria-label="icon: close"] {
        background: white;
        padding: 5px;
    }
}
.app-item-card {
    &.ant-card-bordered {
        border: 1px solid #d8d7d7;
        box-shadow: 4px 3px 5px rgba(150, 150, 150, 0.2);
    }
    .ant-avatar.ant-avatar-circle {
        width: 40px;
        height: auto;
        border-radius: 0;
    }
    .ant-card-meta-avatar {
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        // transform: translate3d(-50%, -50%, 0);
        // transition: transform .3s ease-out;
    }
    .ant-card-body {
        background: #fff9f5;
        position: relative;
    }
    &:hover {
        .ant-card-meta-avatar {
            // transform:translate3d(-50%,-70%,0)
        }
    }
}
.dependencies-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

.hive-item {
    img {
        width: 60px;
        max-height: 88px;
        // transform:scale(0.98);
        opacity: 0.87;
        // transition: transform .2s ease-out, opacity .2s ease-out;
    }
    &:hover {
        img {
            // transform:scale(1) translateY(-2px);
            opacity: 1;
        }
        .instance_app--name {
            color: var(--dark-gray);
        }
    }
}
.instance_app--name {
    font-size: 14px;
    color: var(--med-gray);
    transition: color 0.2s ease-out;
}

.single-instance-avatar.app-page {
    .hex {
        background: var(--purple);
        color: white;
    }
}
.apps-page {
    padding-top: 70px;
    background-color: #f7f5f9;
}
</style>
