<template>
    <div>
        <a-modal
            v-model="colourModal.visible"
            title="Colors"
            @ok="handleColorChange"
        >
            <div class="dF aC" style="flex-wrap: wrap">
                <div
                    class="px-1 py-1"
                    v-for="(color, colorI) in colourList"
                    :key="color + colorI"
                    :value="color"
                >
                    <div
                        class="relative"
                        @click="selectedColor = color"
                        :style="{ 'background-color': color }"
                        style="
                            border-radius: 50%;
                            height: 20px;
                            width: 20px;
                            cursor: pointer;
                        "
                    >
                        <div
                            v-if="selectedColor === color"
                            class="absolute"
                            style="
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            <i class="fe fe-check" />
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
        <template
            v-if="type == 'recent' && instances.length && instances.length > 0"
        >
            <div
                class="dF aC instance-row"
                v-for="(inst, instI) in instances"
                :key="`instances${instI}`"
                style="height: 91px; border-bottom: 2px solid #d8d8d8"
            >
                <div class="px-4 dF aC" style="width: 100%; height: 100%">
                    <div
                        class="icon-option"
                        :class="$mq != 'sm' ? 'mx-4' : 'mr-2'"
                    >
                        <div class="hide-hexagon hexagon hexagon2">
                            <div class="hexagon-in1">
                                <div
                                    class="hexagon-in2"
                                    :style="{
                                        'background-color': getBackground(inst),
                                    }"
                                ></div>
                            </div>
                        </div>
                        <a-popover
                            trigger="click"
                            placement="bottomLeft"
                            overlayClassName="remove-padding"
                        >
                            <div slot="content">
                                <div
                                    @click="pinInstance(inst.id)"
                                    class="popover-option"
                                >
                                    {{
                                        !pin.includes(inst.id) ? "Pin" : "Unpin"
                                    }}
                                </div>
                                <div
									v-if="$userType !== 'agent'"
                                    @click="colourInstance(inst)"
                                    class="popover-option"
                                >
									Color
                                </div>
                                <div
									v-if="$userType !== 'agent'"
                                    @click="settingInstance(inst.id)"
                                    class="popover-option"
                                >
                                    Settings
                                </div>
                            </div>
                            <div class="option-button" tabindex="1">
                                <i
                                    style="font-size: 25px; cursor: pointer"
                                    :style="{ color: getBackground(inst) }"
                                    class="fe fe-more-vertical"
                                />
                            </div>
                        </a-popover>
                    </div>
                    <router-link
                        class="dF aC"
                        :to="`/select/${inst.id}`"
                        style="width: 100%; height: 100%; color: #000"
                    >
                        <div class="f1 mr-2">
                            <div class="dF aC">
                                <div>{{ inst.name }}</div>
                                <i
                                    v-if="pin.includes(inst.id)"
                                    style="color: #fed762"
                                    :style="`font-size:${
                                        $mq === 'sm' ? '13' : '24'
                                    }px`"
                                    class="fa fa-star"
                                    :class="$mq == 'sm' ? 'ml-2' : 'ml-3'"
                                />
                            </div>
                            <div style="color: #757575">
                                {{
                                    !inst.children || inst.children.length == 0
                                        ? ""
                                        : inst.children.length == 1
                                        ? `${inst.children.length} instance connected`
                                        : `${inst.children.length} projects linked`
                                }}
                            </div>
                        </div>
                        <div class="dF aC instance-glance">
                            <div
                                class="mr-3 f1"
                                style="color: #757575; width: 116px"
                                v-if="$mq !== 'sm'"
                            >
                                {{
                                    lastVisited[inst.id]
                                        ? convertDate(lastVisited[inst.id])
                                        : ""
                                }}
                            </div>
                            <div
                                :style="
                                    $mq != 'sm' ? 'width:110px' : 'width:53px'
                                "
                            >
                                <a-tooltip
                                    slot="prefix"
                                    :title="
                                        inst.own ? 'Owned' : 'Shared with you'
                                    "
                                    overlayClassName="change-tooltip-color"
                                >
                                    <i
                                        style="color: #d0c9d6"
                                        v-if="inst.own"
                                        class="fe fe-user"
                                    />
                                    <i
                                        style="color: #d0c9d6"
                                        v-else
                                        class="fe fe-users"
                                    />
                                </a-tooltip>
                                <a-tooltip
                                    :class="$mq != 'sm' ? 'ml-3' : 'ml-2'"
                                    v-if="linkedInstances.includes(inst.id)"
                                    slot="prefix"
                                    title="Linked"
                                    overlayClassName="change-tooltip-color"
                                >
                                    <i
                                        style="color: #d0c9d6"
                                        class="fe fe-link"
                                    />
                                </a-tooltip>
                                <a-tooltip
                                    :class="$mq != 'sm' ? 'ml-3' : 'ml-2'"
                                    v-if="
                                        inst.children &&
                                        inst.children.length > 0
                                    "
                                    slot="prefix"
                                    title="Parent Project"
                                    overlayClassName="change-tooltip-color"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 20.4 16.32"
                                        fill="#FF9027"
                                    >
                                        <path
                                            id="Icon_awesome-crown"
                                            data-name="Icon awesome-crown"
                                            d="M16.83,14.28H3.57a.511.511,0,0,0-.51.51v1.02a.511.511,0,0,0,.51.51H16.83a.511.511,0,0,0,.51-.51V14.79A.511.511,0,0,0,16.83,14.28Zm2.04-10.2a1.53,1.53,0,0,0-1.53,1.53,1.5,1.5,0,0,0,.14.631L15.172,7.624a1.019,1.019,0,0,1-1.409-.37l-2.6-4.545a1.53,1.53,0,1,0-1.932,0l-2.6,4.545a1.019,1.019,0,0,1-1.409.37l-2.3-1.383a1.529,1.529,0,1,0-1.393.9,1.562,1.562,0,0,0,.245-.025l2.3,6.145H16.32l2.3-6.145a1.562,1.562,0,0,0,.245.025,1.53,1.53,0,1,0,0-3.06Z"
                                            class="cls-1"
                                        ></path>
                                    </svg>
                                </a-tooltip>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </template>
        <draggable
            v-bind="dragOptions"
            @change="(e) => onChange(instances, e)"
            v-if="type != 'recent' && instances.length && instances.length > 0"
            :list="instances"
            @start="dragging = true"
			@end="dragging = false"
			:delay-on-touch-only="true"
			:delay="100"
        >
            <div
                class="dF aC instance-row"
                v-for="(inst, instI) in instances"
                :key="`instances${instI}`"
                style="height: 91px; border-bottom: 2px solid #d8d8d8"
            >
                <div class="px-4 dF aC" style="width: 100%; height: 100%">
                    <div
                        class="icon-option"
                        :class="$mq != 'sm' ? 'mx-4' : 'mr-2'"
                    >
                        <div class="hide-hexagon hexagon hexagon2">
                            <div class="hexagon-in1">
                                <div
                                    class="hexagon-in2"
                                    :style="{
                                        'background-color': getBackground(inst),
                                    }"
                                ></div>
                            </div>
                        </div>
                        <a-popover
                            trigger="click"
                            placement="bottomLeft"
                            overlayClassName="remove-padding"
                        >
                            <div slot="content">
                                <div
                                    v-if="page != 'project'"
                                    @click="pinInstance(inst.id)"
                                    class="popover-option"
                                >
                                    {{
                                        !pin.includes(inst.id) ? "Pin" : "Unpin"
                                    }}
                                </div>
                                <div
									v-if="$userType !== 'agent'"
                                    @click="colourInstance(inst)"
                                    class="popover-option"
                                >
									Color
                                </div>
                                <div
									v-if="$userType !== 'agent'"
                                    @click="settingInstance(inst.id)"
                                    class="popover-option"
                                >
                                    {{
                                        page == "project"
                                            ? "Edit Project"
                                            : "Settings"
                                    }}
                                </div>
								<div
                                    v-if="inst.own || inst.role?.permissions?.billing >= 10"
                                    @click="billingInstance(inst.id)"
                                    class="popover-option"
                                >
                                    Billing
                                </div>
                                <div
                                    class="popover-option"
                                    v-if="page == 'project' && inst.own"
                                    @click="$emit('deleteInstance', inst.id)"
                                >
                                    Delete Project
                                </div>
                            </div>
                            <div class="option-button" tabindex="0">
                                <i
                                    style="font-size: 25px; cursor: pointer"
                                    :style="{ color: getBackground(inst) }"
                                    class="fe fe-more-vertical"
                                />
                            </div>
                        </a-popover>
                    </div>
                    <router-link
                        class="dF aC"
                        :to="`/select/${inst.id}`"
                        style="width: 100%; height: 100%; color: #000"
                    >
                        <div class="f1">
                            <div class="dF aC">
                                <div>{{ inst.name }}</div>
                                <i
                                    v-if="pin.includes(inst.id)"
                                    style="color: #fed762"
                                    :style="`font-size:${
                                        $mq === 'sm' ? '13' : '24'
                                    }px`"
                                    class="fa fa-star"
                                    :class="$mq == 'sm' ? 'ml-2' : 'ml-3'"
                                />
								<a-tag v-if="inst.isDemo" class="ml-3">
									Demo
								</a-tag>
                            </div>
                            <div style="color: #757575">
                                {{
                                    !inst.children || inst.children.length == 0
                                        ? ""
                                        : inst.children.length == 1
                                        ? `${inst.children.length} project linked`
                                        : `${inst.children.length} projects linked`
                                }}
                            </div>
                        </div>
                        <div class="dF aC instance-glance">
                            <div
                                class="mr-3 f1"
                                style="color: #757575; width: 116px"
                                v-if="$mq !== 'sm'"
                            >
                                {{
                                    lastVisited[inst.id]
                                        ? convertDate(lastVisited[inst.id])
                                        : ""
                                }}
                            </div>
                            <div
                                :style="
                                    $mq != 'sm' ? 'width:110px' : 'width:53px'
                                "
                            >
                                <a-tooltip
                                    slot="prefix"
                                    :title="
                                        inst.own ? 'Owned' : 'Shared with you'
                                    "
                                    overlayClassName="change-tooltip-color"
                                >
                                    <i
                                        style="color: #d0c9d6"
                                        v-if="inst.own"
                                        class="fe fe-user"
                                    />
                                    <i
                                        style="color: #d0c9d6"
                                        v-else
                                        class="fe fe-users"
                                    />
                                </a-tooltip>
                                <a-tooltip
                                    :class="$mq == 'sm' ? 'ml-2' : 'ml-3'"
                                    v-if="linkedInstances.includes(inst.id)"
                                    slot="prefix"
                                    title="Linked"
                                    overlayClassName="change-tooltip-color"
                                >
                                    <i
                                        style="color: #d0c9d6"
                                        class="fe fe-link"
                                    />
                                </a-tooltip>
                                <a-tooltip
                                    :class="$mq == 'sm' ? 'ml-2' : 'ml-3'"
                                    v-if="
                                        inst.children &&
                                        inst.children.length > 0
                                    "
                                    slot="prefix"
                                    title="Parent Project"
                                    overlayClassName="change-tooltip-color"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 20.4 16.32"
                                        fill="#FF9027"
                                    >
                                        <path
                                            id="Icon_awesome-crown"
                                            data-name="Icon awesome-crown"
                                            d="M16.83,14.28H3.57a.511.511,0,0,0-.51.51v1.02a.511.511,0,0,0,.51.51H16.83a.511.511,0,0,0,.51-.51V14.79A.511.511,0,0,0,16.83,14.28Zm2.04-10.2a1.53,1.53,0,0,0-1.53,1.53,1.5,1.5,0,0,0,.14.631L15.172,7.624a1.019,1.019,0,0,1-1.409-.37l-2.6-4.545a1.53,1.53,0,1,0-1.932,0l-2.6,4.545a1.019,1.019,0,0,1-1.409.37l-2.3-1.383a1.529,1.529,0,1,0-1.393.9,1.562,1.562,0,0,0,.245-.025l2.3,6.145H16.32l2.3-6.145a1.562,1.562,0,0,0,.245.025,1.53,1.53,0,1,0,0-3.06Z"
                                            class="cls-1"
                                        ></path>
                                    </svg>
                                </a-tooltip>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </draggable>
        <template v-if="type == 'invitation'"></template>
        <div
            v-if="!instances.length || instances.length == 0"
            class="align-center mt-5"
        >
            <img
                style="width: 100%; max-width: 500px"
                src="@/assets/noinstance_found.svg"
            />
            <h3 class="mt-5">No Project Found</h3>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    components: { draggable },
    props: {
        instances: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: "",
        },
        page: {
            type: String,
            default: "",
        },
    },
    watch: {
        colourModal: {
            handler(val) {
                if (val.visible) {
                    const color = this.getBackground(val.instance);
                    if (color !== "") {
                        this.selectedColor = color;
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
        dragOptions() {
            let obj = {
                "data-hello": "world",
            };
            if (this.$mq === "sm") obj.handle = ".handle";
            return obj;
        },
        pin() {
            if (
                this.$store.state.user.user &&
                this.$store.state.user.user.settings &&
                this.$store.state.user.user.settings.options &&
                this.$store.state.user.user.settings.options.pin &&
                this.$store.state.user.user.settings.options.pin.length != 0
            ) {
                return this.$store.state.user.user.settings.options.pin;
            } else return [];
        },
        user() {
            return this.$store.state.user;
        },
        userSettings() {
            if (
                this.$store.state.user.user.settings != null &&
                Object.keys(this.$store.state.user.user.settings).length != 0
            ) {
                return this.$store.state.user.user.settings;
            } else return {};
        },
        lastVisited() {
            if (
                this.$store.state.user.user &&
                this.$store.state.user.user.settings &&
                this.$store.state.user.user.settings.options &&
                this.$store.state.user.user.settings.options.lastVisited
            ) {
                return this.$store.state.user.user.settings.options.lastVisited;
            } else {
                return {};
            }
        },
        linkedInstances() {
            let linked = [];
            this.storeInstances.forEach((instance) => {
                if (instance.children && instance.children.length != 0) {
                    if (!linked.includes(instance.id)) {
                        linked.push(instance.id);
                    }
                    instance.children.forEach((child) => {
                        if (
                            typeof child == "Object" &&
                            !linked.includes(child.id)
                        ) {
                            linked.push(child.id);
                        } else if (
                            typeof child != "Object" &&
                            !linked.includes(child)
                        ) {
                            linked.push(child);
                        }
                    });
                }
            });
            return linked;
        },
        storeInstances() {
            return this.$store.state.instances;
        },
    },
    data() {
        return {
            colourModal: {
                visible: false,
                instance: {},
            },
            colourList: [
                "#FEC267",
                "#C1BCF4",
                "#89C6E5",
                "#7CDDC1",
                "#FCAFBA",
                "#3A558A",
                "#CD4386",
                "#E089B8",
                "#EF6D8B",
                "#FFDCE6",
                "#227949",
                "#9CB63E",
                "#C7E77A",
                "#18A1B1",
                "#58CAD5",
                "#F3AC46",
                "#FED762",
                "#0A5A93",
                "#83776B",
                "#BEB7AC",
                "#EEDDBF",
                "#F3F1EC",
                "#AE9898",
                "#5AF8E1",
                "#52C4FD",
                "#AB81FC",
                "#C3C3C5",
                "#FD395D",
                "#ACECF9",
                "#FECCAC",
                "#D18063",
                "#917B56",
                "#FDA28F",
                "#7FFD80",
                "#AAD7CF",
                "#D8ECE2",
                "#CBCFE0",
                "#7F7F7F",
                "#B2B2B2",
                "#E5E5E5",
            ],
            selectedColor: "",
        };
    },
    methods: {
        onChange(array, e) {
            let options = {};
            if (
                this.userSettings.options &&
                Object.keys(this.userSettings.options).length != 0
            ) {
                options = JSON.parse(JSON.stringify(this.userSettings.options));
                if (!options.reOrder) {
                    let reOrder = {};
                    reOrder[this.type] = array.map((x) => (x = x.id));
                    options.reOrder = reOrder;
                } else {
                    options.reOrder[this.type] = array.map((x) => (x = x.id));
                }
            } else {
                let reOrder = {};
                reOrder[this.type] = array.map((x) => (x = x.id));
                options.reOrder = reOrder;
            }
            this.$api
                .post("/settings", { options: options })
                .then(({ data }) => {
                    let user = JSON.parse(JSON.stringify(this.user.user));
                    user.settings = data;
                    this.$store.commit("UPDATE_USER", user);
                }).catch((err) => console.error(err));
        },
        convertDate(num) {
            let x = new Date(num);
            let months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "June",
                "Jul",
                "Aug",
                "Sept",
                "Oct",
                "Nov",
                "Dec",
            ];
            //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
            let year = x.getFullYear();
            let month = x.getMonth() + 1;
            let day = x.getDate();
            let hour = x.getHours();
            let minute = x.getMinutes();
            if (minute.toString().length == 1) {
                minute = "0" + minute.toString();
            }
            return (
                day +
                " " +
                months[month - 1] +
                " " +
                year +
                " " +
                hour +
                ":" +
                minute
            );
        },
        getBackground(instance) {
            const avatar = instance.avatar;
            const id = instance.id;
            let found = this.storeInstances.find((x) => x.id === id);
            if (found) {
                if (found.color) {
                    if (
                        this.userSettings.options &&
                        this.userSettings.options.colour &&
                        this.userSettings.options.colour[id]
                    ) {
                        return this.userSettings.options.colour[id];
                    } else if (
                        !this.userSettings.options ||
                        !this.userSettings.options.colour ||
                        !this.userSettings.options.colour[id] ||
                        this.userSettings.options.colour[id] == ""
                    ) {
                        return found.color;
                    }
                } else {
                    if (
                        this.userSettings.options &&
                        this.userSettings.options.colour &&
                        this.userSettings.options.colour[id]
                    ) {
                        return this.userSettings.options.colour[id];
                    } else if (avatar && typeof avatar === "string") {
                        return avatar;
                    } else return "#FEC267";
                }
            } else return "";
        },
        endMove(e) {},
        chooseColor(color) {
            this.selectedColor = color;
        },
        pinInstance(id) {
            let options = {};
            let find = this.storeInstances.find((inst) => inst.id == id);

            if (Object.keys(this.userSettings.options).length != 0) {
                let storeOptions = JSON.parse(
                    JSON.stringify(this.userSettings.options)
                );
                Object.keys(storeOptions).forEach((key) => {
                    options[key] = storeOptions[key];
                });
                if (!options.pin) {
                    let pin = [];
                    options.pin = pin;
                    options.pin.push(id);
                } else {
                    if (options.pin.includes(id)) {
                        let index = options.pin.findIndex((x) => x == id);
                        options.pin.splice(index, 1);
                    } else {
                        options.pin.push(id);
                    }
                }
            } else {
                let pin = [];
                options.pin = pin;
                options.pin.push(id);
            }
            this.$api
                .post("/settings", { options: options })
                .then(({ data }) => {
                    let user = JSON.parse(JSON.stringify(this.user.user));
                    user.settings = data;
                    this.$store.commit("UPDATE_USER", user);
                    if (find) this.$emit("updatePin", find);
                }).catch((err) => console.error(err));

			let popoverElement = document.querySelector('.ant-popover')
			if (popoverElement) popoverElement.style.display = 'none';
        },
        colourInstance(instance) {
            this.colourModal.instance = instance;
            this.colourModal.visible = true;
        },
        settingInstance(id) {
            let found = this.storeInstances.find((x) => x.id === id);
            if (found) {
                this.$store.commit("OPEN_EDIT_INSTANCE", found);
                this.$router.push("/settings/projects/edit");
            }
        },
        handleColorChange() {
            if (this.selectedColor != "") {
                let options = {};
                if (
                    this.userSettings.options &&
                    Object.keys(this.userSettings.options).length != 0 &&
                    this.userSettings != null &&
                    Object.keys(this.userSettings).length != 0
                ) {
                    let storeOptions = JSON.parse(
                        JSON.stringify(this.userSettings.options)
                    );
                    Object.keys(storeOptions).forEach((key) => {
                        options[key] = storeOptions[key];
                    });
                    if (!options.colour) {
                        let colour = {};
                        options.colour = colour;
                        options.colour[this.colourModal.instance.id] =
                            this.selectedColor;
                    } else {
                        options.colour[this.colourModal.instance.id] =
                            this.selectedColor;
                    }
                } else {
                    let colour = {};
                    options.colour = colour;
                    options.colour[this.colourModal.instance.id] =
                        this.selectedColor;
                }
                this.$api
                    .post("/settings", { options: options })
                    .then(({ data }) => {
                        let user = JSON.parse(JSON.stringify(this.user.user));
                        user.settings = data;
                        this.$store.commit("UPDATE_USER", user);
                        this.colourModal.visible = false;
                        this.colourModal.instance = {};
                        this.selectedColor = "";
                    }).catch((err) => console.error(err));
            }
        },

		billingInstance(id) {
            // instance billing details
            this.$router.push(`/settings/billing?instance=${id}`);
        },
    },
};
</script>

<style scoped lang="scss">
.popover-option {
    cursor: pointer;
    height: 35px;
    //   width: 120px;
    line-height: 35px;
    padding-left: 10px;
    padding-right: 10px;
}
.popover-option:hover {
    background-color: #faf9f9;
    color: #000;
}
.hexagon {
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
    cursor: pointer;
}
.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon-in2 {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-color: #d0c9d6;
    visibility: visible;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon2 {
    width: 25px;
    height: 50px;
    margin: -20px 0 -20px 0;
}
.icon-option {
    width: 25px;
    height: 100%;
    display: flex;
    align-items: center;
}
.option-button {
    text-align: center;
    height: 25px;
}
.instance-row:hover {
    .hide-hexagon {
        display: none;
    }
    .option-button {
        display: block;
    }
}
.instance-row:not(:hover) {
    .hide-hexagon {
        display: block;
    }
    .option-button {
        display: none;
    }
}
</style>
<style scoped>
.instance-row:hover {
    background-color: var(--off-white-dark);
}
</style>
<style lang="scss">
.change-tooltip-color .ant-tooltip-content .ant-tooltip-inner {
    background: #3f3356;
}
@media screen and (max-width: 576px) {
    .instance-glance {
        i {
            font-size: 13px;
        }
        svg {
            width: 13px;
            height: 13px;
        }
    }
}
@media screen and (min-width: 576px) {
    .instance-glance {
        i {
            font-size: 20px;
        }
        svg {
            width: 20px;
            height: 20px;
        }
    }
}
</style>
